<!-- BlocLink vue -->
<template lang="pug">
g.line(v-bind:class="[{'selected':selected}, {'activated':link.activated}, {'deprecated':isDeprecated}]", v-on:click.stop="selectPath($event)")
	g(v-if="link && !impossible")

		g(v-for="(bezier,b) in path.beziers")
			line(v-for="(loop,i) in looper[b]", :x1="i==0 ? getPointAt(bezier, looper[b][0]).x : getPointAt(bezier, looper[b][i-1]).x", :y1="i==0 ? getPointAt(bezier, looper[b][0]).y : getPointAt(bezier, looper[b][i-1]).y", :x2="getPointAt(bezier, loop).x", :y2="getPointAt(bezier, loop).y")
			line.invisible(v-for="(loop,i) in looper[b]", :x1="i==0 ? getPointAt(bezier, looper[b][0]).x : getPointAt(bezier, looper[b][i-1]).x", :y1="i==0 ? getPointAt(bezier, looper[b][0]).y : getPointAt(bezier, looper[b][i-1]).y", :x2="getPointAt(bezier, loop).x", :y2="getPointAt(bezier, loop).y")

		v-touch#grab(tag="g", v-on:pan="onPan")
			line.grabber-bg(v-if="path && path.beziers && path.beziers.length >= 2 ", :x1="getPointAt(path.beziers[0], grabberStart).x", :x2="getPointAt(path.beziers[1], grabberEnd).x", :y1="getPointAt(path.beziers[0], grabberStart).y", :y2="getPointAt(path.beziers[1], grabberEnd).y")
			line.grabber(v-if="path && path.beziers && path.beziers.length >= 2 ", :x1="getPointAt(path.beziers[0], grabberStart).x", :x2="getPointAt(path.beziers[1], grabberEnd).x", :y1="getPointAt(path.beziers[0], grabberStart).y", :y2="getPointAt(path.beziers[1], grabberEnd).y")
			path.grabberTextPath(v-bind:id="linkuuid", v-bind:d="grabberTextPath")
			text(width="100", text-anchor="middle")
				textPath(v-if="link.conversion", v-bind:xlink:href="'#'+linkuuid", startOffset="50%", alignment-baseline="middle", text-anchor="middle") {{link.conversion}}
				textPath(v-else, v-bind:xlink:href="'#'+linkuuid", startOffset="50%", alignment-baseline="middle", text-anchor="middle") >

		g(v-if="this.link.priority != 0 && priorityPos")
			circle(:cx="priorityPos.x", :cy="priorityPos.y", r="9", fill="#F49E75", stroke="none" )
			text(width="6", :x="priorityPos.x", :y="priorityPos.y", text-anchor="middle", fill="white", stroke="none", font-size="9px", alignment-baseline="central" ) {{link.priority}}
</template>

<script>

// STORE
import * as helper from '../../../helper';

import actionManager from '../../../actions';

import * as d3 from 'd3';
import * as BezierCurve from 'bezier-curve';

import uuid from 'uuid';

const lineGenerator = d3.line().curve(d3.curveBasis);

export default {

	props: ['appState', 'link'],

	data() {
		return {
			// path:'M0 0',

			impossible:false,
			deleted: false,

			firstSpace: 40,
			space: 2,
			created: false,

			blockFrom: null,
			blockTo: null,

			pathMiddle: null,

			deprecated: null,

			draggingShift: false,
			shift: { x: 0, y: 0 },
			lastShift: { x: 0, y: 0 },
			pollFreq : 30,
			prevTime : 0,

			curve: null,
			nbSteps: 30,
			grabberStart : 0.9,
			grabberEnd : 0.1,

			linkuuid : uuid()
		}
	},

	computed: {

		'priorityPos': function(){
			if( this.path && this.path.beziers && this.path.beziers.length > 1 && this.link.priority !== 0 ){
				return this.getPointAt( this.path.beziers[0], this.grabberStart-0.04 );
			}
			return null;
		},

		'isDeprecated': function(){
			if(this.blockFrom || this.blockTo){
				if((this.blockFrom && this.blockFrom.value.deprecated) || (this.blockTo && this.blockTo.value.deprecated)) return true
				if((this.fieldFrom && this.fieldFrom.deprecated) || (this.fieldTo && this.fieldTo.deprecated)){
					return true
				}
			}
			return false
		},

		'looper': function(){
			let loops = [];
			for(let b = 0 ; b < 2 ; b++ ){
				let loop =  [];
				let current = 0;
				if( b == 1 )
					loop.push(this.grabberEnd );

				for( let i = 0 ; i<this.nbSteps ; i++ ){
					if( ( b == 0 && current < this.grabberStart ) || ( b == 1 && current > this.grabberEnd ) )
						loop.push( current );
					current+= 1/this.nbSteps;
				}
				if( b == 0 )
					loop.push( this.grabberStart );
				if( b == 1 )
					loop.push( 1 );

				loops.push(loop);
			}

			return loops;
		},

		'grabberTextPath': function(){
			if( this.path && this.path.beziers && this.path.beziers.length == 2 ){

				let start = this.getPointAt( this.path.beziers[0], this.grabberStart)
				let end = this.getPointAt(this.path.beziers[1], this.grabberEnd)

				return 'M '+start.x+' '+start.y+' L '+end.x+' '+end.y;
			}
		},

		'fieldFrom': function(){
			let field = this.getField( this.blockFrom, this.link.from.name );
			return field ? field : null;
		},

		'fieldTo': function(){
			let field = this.getField( this.blockTo, this.link.to.name );
			return field ? field : null;
		},

		'selected': function(){
			if( this.appState.editor.linkmenu && this.appState.editor.linkmenu.open ){
				let id = this.link.from.blocID+"-"+this.link.from.side+"-"+this.link.from.name+"__"+this.link.to.blocID+"-"+this.link.to.side+"-"+this.link.to.name;
				if( id == this.appState.editor.linkmenu.id )
					return true;
			}
			return false;
		},

		'fromPos' : function(){

			if( this.blockFrom && this.blockFrom.value.coordinate && this.fieldFrom && this.fieldFrom.anchorPos ){
				return {
					x: this.blockFrom.value.coordinate.x + this.fieldFrom.anchorPos[this.link.from.side].x,
					y: this.blockFrom.value.coordinate.y + this.fieldFrom.anchorPos[this.link.from.side].y
				}
			}
			return null;
		},

		'toPos' : function(){

			if( this.blockTo && this.blockTo.value.coordinate && this.fieldTo && this.fieldTo.anchorPos ){
				return {
					x: this.blockTo.value.coordinate.x + this.fieldTo.anchorPos[this.link.to.side].x,
					y: this.blockTo.value.coordinate.y + this.fieldTo.anchorPos[this.link.to.side].y
				}
			}
			return null;
		},

		'middle': function(){
			if( this.path && this.pathMiddle && this.shift )
				return { x : this.pathMiddle.x , y : this.pathMiddle.y };
			return { x : 0 , y : 0 };
		},

		'path' : function(){
			let curPath = 'M0 0';

			if( this.created && this.fromPos && this.toPos ){

				let orderFrom = this.fieldFrom.order[this.link.from.side];
				let orderTo = this.fieldTo.order[this.link.to.side];
				// Decalage un peu a coté de l'ancre du field
				let from_ext = Object.assign({}, this.fromPos);

				if( this.link.from.side == "input" )
					from_ext.x = from_ext.x - this.firstSpace - (orderFrom * this.space);
				else
					from_ext.x = from_ext.x + this.firstSpace + (orderFrom * this.space);

				// Decalage un peu a coté de l'ancre du field
				let to_ext = Object.assign({}, this.toPos);

				if( this.link.to.side == "input" )
					to_ext.x = to_ext.x - this.firstSpace - (orderTo * this.space);
				else
					to_ext.x = to_ext.x + this.firstSpace + (orderTo * this.space);
				
				//the following lines are meant to avoid value middle to change after
				//the deletion of the addition of a link in the schematic.
				//It forces the shift value to stick to the link prop (this.link) and to the declared data (this.shift)
				let shiftX;
				let shiftY;
				if(this.link.shift){
					shiftX = this.link.shift.x;
					shiftY = this.link.shift.y
				} else {
					shiftX = 0;
					shiftY = 0;
				}
				let middle = {
					x: (from_ext.x+to_ext.x)*0.5 + (orderTo * this.space) + (orderFrom * this.space) + shiftX,
					y: (from_ext.y+to_ext.y)*0.5 + shiftY
				}

				let points = [];
				let pointsToCurve = [];

				let deltaX = this.toPos.x-this.fromPos.x;
				let deltaY = Math.abs(this.toPos.y-this.fromPos.y);

				let nbSteps = 10;

				if( deltaX > 0 && deltaX < this.firstSpace*2 && deltaY < this.firstSpace*2 ){

					/*points = [ 	[ this.fromPos.x , this.fromPos.y ],
								[ from_ext.x , from_ext.y ],
								[ to_ext.x , to_ext.y ],
								[ this.toPos.x , this.toPos.y ],
							];*/

					pointsToCurve = [
							{ pos : BezierCurve.v2( this.fromPos.x , this.fromPos.y ), out: BezierCurve.v2( from_ext.x , from_ext.y ) },
							{ pos : BezierCurve.v2( middle.x , middle.y ), in: BezierCurve.v2( from_ext.x , from_ext.y ), out: BezierCurve.v2( to_ext.x , to_ext.y )},
							{ pos : BezierCurve.v2( this.toPos.x , this.toPos.y ), in: BezierCurve.v2( to_ext.x , to_ext.y ) }
						];


				}
				else if( this.link.from.side != this.link.to.side && ( (from_ext.x < to_ext.x && this.link.from.side == "output") || (from_ext.x > to_ext.x && this.link.from.side == "input") ) ){
					/*curPath = 'M'+this.fromPos.x+' '+this.fromPos.y+' ';
					curPath += 'L'+middle.x+' '+this.fromPos.y+' ';
					curPath += 'L'+middle.x+' '+this.toPos.y+' ';
					curPath += 'L'+this.toPos.x+' '+this.toPos.y;

					points = [ 	[ this.fromPos.x , this.fromPos.y ],
								[ middle.x , this.fromPos.y ],
								[ middle.x , middle.y],
								[ middle.x , this.toPos.y ],
								[ this.toPos.x , this.toPos.y ],
							];*/

					pointsToCurve = [
							{ pos : BezierCurve.v2( this.fromPos.x , this.fromPos.y ), out: BezierCurve.v2( from_ext.x , from_ext.y ) },
							{ pos : BezierCurve.v2( middle.x , middle.y ), in: BezierCurve.v2( from_ext.x , from_ext.y ), out: BezierCurve.v2( to_ext.x , to_ext.y )},
							{ pos : BezierCurve.v2( this.toPos.x , this.toPos.y ), in: BezierCurve.v2( to_ext.x , to_ext.y ) }
						];

				}
				else{
					/*curPath = 'M'+this.fromPos.x+' '+this.fromPos.y+' ';
					curPath += 'L'+from_ext.x+' '+from_ext.y+' ';
					curPath += 'L'+from_ext.x+' '+middle.y+' ';
					curPath += 'L'+to_ext.x+' '+middle.y+' ';
					curPath += 'L'+to_ext.x+' '+to_ext.y;
					curPath += 'L'+this.toPos.x+' '+this.toPos.y;

					points = [ 	[ this.fromPos.x , this.fromPos.y ],
								[ from_ext.x , from_ext.y ],
								[ from_ext.x , middle.y ],
								[ middle.x , middle.y],
								[ to_ext.x , middle.y ],
								[ to_ext.x , to_ext.y ],
								[ this.toPos.x , this.toPos.y ],
							];*/
					pointsToCurve = [
							{ pos : BezierCurve.v2( this.fromPos.x , this.fromPos.y ), out: BezierCurve.v2( from_ext.x , from_ext.y ) },
							{ pos : BezierCurve.v2( middle.x , middle.y ), in: BezierCurve.v2( from_ext.x , middle.y ), out: BezierCurve.v2( to_ext.x , middle.y )},
							{ pos : BezierCurve.v2( this.toPos.x , this.toPos.y ), in: BezierCurve.v2( to_ext.x , to_ext.y ) }
						];

					nbSteps = 20;
				}


				//let pathData = lineGenerator(points);
				this.pathMiddle = middle;



				this.curve = new BezierCurve.Curve(pointsToCurve);

				/*let decomp = [];
				this.curve.beziers.forEach( (bezier) => {
					for( let i = 0 ; i <= 1 ; i+= 1/nbSteps ){
						decomp.push( bezier.getPointAt(i) );
					}
				});
				decomp.push( { x: this.toPos.x , y: this.toPos.y } );

				return decomp;*/
				return this.curve;


			}

			return [];
			//return curPath;
		}


	},

	mounted: function() {
		this.setBlocksReferent();

		if( this.link.shift ){
			this.shift = {x: this.link.shift.x, y:this.link.shift.y };
			this.lastShift = {x: this.link.shift.x, y:this.link.shift.y };
		}

		this.created = true;
	},

	// The Vue instance lifecycle has changed, referents must be redefined on update.
	// since migration Vue v1 to v2
	updated: function() { this.setBlocksReferent() },

	methods: {

		setBlocksReferent: function (){
			this.blockFrom = helper.block.getBlockById( this.appState.project, this.link.from.blocID );
			this.blockTo = helper.block.getBlockById( this.appState.project, this.link.to.blocID );
		},
		getPointAt( bezier, percent ){
			return bezier.getPointAt( percent );
		},

		getField( block, fieldname ){
			// let allConnexions = Object.assign( {}, block.custom.states, block.custom.fields );
			// let fields = Object.keys( allConnexions );

			// let result = null;
			// if ()
			// fields.forEach( (field) => {
			// 	if( field == fieldname ){
			// 		result = allConnexions[field] ;
			// 	}
			// });
			if (block && block.custom) return block.custom.states[fieldname] || block.custom.fields[fieldname];
			return null;
		},

		selectPath: function( event ){
			if (!this.draggingShift) {
				let origin = this.appState.editor.origin;
				actionManager.trigger('editor:schematic:toggleLinkMenu',  { subaction: "open", link: this.link, x : event.clientX-origin.x, y : event.clientY-origin.y } );
			}
		},

		onPan: function( e ){
			this.draggingShift = true;
			e.triggeredInLine = true;

			let delta = { x : e.deltaX , y: e.deltaY };

			this.shift.x = this.lastShift.x + delta.x + ( delta.x * (1-this.appState.editor.scene.scale) );
			this.shift.y = this.lastShift.y + delta.y + ( delta.y * (1-this.appState.editor.scene.scale) );
			let time = this.getTimestamp();
			let diff = time - this.prevTime;


			if( diff > this.pollFreq ){
				actionManager.trigger('editor:schematic:moveLinkGrabber', { subaction:'drag' , blocId: this.blockFrom.value._id, link: this.link, shift: this.shift } );// , id : this.bloc.value._id, x: this.coordinates.x , y : this.coordinates.y });
				this.prevTime = time;
			}

			if( e.isFinal ){

				this.lastShift = Object.assign( {}, this.shift );

				setTimeout( function(){
					this.draggingShift = false;
					actionManager.trigger('editor:schematic:moveLinkGrabber', { subaction:'drop' , blocId: this.blockFrom.value._id, link: this.link, shift: this.shift } );
					//actionManager.trigger('editor:schematic:moveBlock', { subaction:'drop', id : this.bloc.value._id, x: this.coordinates.x , y : this.coordinates.y });
				}.bind(this), 0);
			}
			if( !e.isFinal && !e.isFirst && e.srcEvent ){
				e.srcEvent.preventDefault();
				e.srcEvent.stopImmediatePropagation();
			}

			e.preventDefault();
		},

		getTimestamp(){
			var d = new Date();
			var n = d.getTime();
			return n;
		}

	}

}

</script>

<style lang="stylus">

</style>
