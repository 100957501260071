import ActionManager from '../ActionManager';

import OpenProject from './OpenProject';
ActionManager.register(OpenProject, ['stores:MainStore']);

import CreateProject from './CreateProject';
ActionManager.register(CreateProject, ['stores:MainStore']);

import DeleteProject from './DeleteProject';
ActionManager.register(DeleteProject, ['stores:MainStore']);

import AddMemory from './AddMemory';
ActionManager.register(AddMemory, ['stores:MainStore']);

import DeleteMemory from './DeleteMemory';
ActionManager.register(DeleteMemory, ['stores:MainStore']);

import ChangeXpTarget from './ChangeXpTarget';
ActionManager.register(ChangeXpTarget, ['stores:MainStore']);

import Save from './Save';
ActionManager.register(Save, ['stores:MainStore']);

import DownloadProject from './DownloadProject';
ActionManager.register(DownloadProject, ['stores:MainStore']);

import AddSession from './AddSession';
ActionManager.register(AddSession, ['stores:MainStore']);

import DeleteSession from './DeleteSession';
ActionManager.register(DeleteSession, ['stores:MainStore']);

import QuitProject from './QuitProject';
ActionManager.register(QuitProject);

import AddTrainingSet from './AddTrainingSet';
ActionManager.register(AddTrainingSet, ['stores:MainStore']);

import DuplicateProject from './DuplicateProject';
ActionManager.register(DuplicateProject, ['stores:MainStore']);
