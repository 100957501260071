var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "accordion topic",
      class: [
        { "invisible-setting": _vm.bloc.invisible },
        { hide: _vm.isObj && _vm.bloc.hideForObjInspector === true }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "accordion-title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.accordion = !_vm.accordion
            }
          }
        },
        [
          _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.bloc.title))]),
          _vm.bloc.isAccordion
            ? _c("span", {
                staticClass: "arrow",
                class: { close: !_vm.accordion }
              })
            : _vm._e()
        ]
      ),
      _vm.bloc.children && _vm.bloc.children.length > 0
        ? _c(
            "div",
            { staticClass: "form-content", class: { open: _vm.accordion } },
            _vm._l(_vm.bloc.children, function(subbloc) {
              return _c(
                "div",
                { staticClass: "children" },
                [
                  (subbloc.type == "group" || subbloc.type == "param") &&
                  _vm.hasChild(subbloc) &&
                  _vm.conditionIsFilled(subbloc)
                    ? _c("group", {
                        attrs: {
                          project: _vm.project,
                          target: _vm.target,
                          bloc: subbloc,
                          state: _vm.state,
                          inspectorField: _vm.inspectorField
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }