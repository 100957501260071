/*
	Memory Store instance -
	all function to get info of Memory
*/

import { EventEmitter } from 'events';
import Vue from 'vue';
import VueResource from 'vue-resource';
import * as helper from '../helper';

Vue.use(VueResource);


class MemoryStore extends EventEmitter {
	constructor(){
		super();
	}

	setMain( main ){
		this.main = main;
	}

	// Add default fields to the memory inspector (name and shared parameter)
	addDefaultMemoryConfig( setting ){
		if( setting.value.inspector && setting.value.inspector.children ){

			let globalConfig = { "type": "topic", "title": "Global settings", "isAccordion": true, "children": [ ] };

			globalConfig.children.push( {
				"type": "param",
				"title": "Name",
				"isAccordion": false,
				"widget": "string",
				"default": setting.value.name,
				"name": "name",
				"resume" : "Specified the name given to the memory." 
			});

			if(setting.value.format != 'secret'){
				globalConfig.children.push( {
					"type": "param",
					"title": "Shared",
					"isAccordion": false,
					"widget": "boolean",
					"default": false,
					"name": "shared",
					"exclude_level" : ['process'],
					"resume" : "If activated, the value of the memory is shared between all the connected devices. If the value is changed, all the devices with get the new value." 
				});
	
				globalConfig.children.push( {
					"type": "param",
					"title": "Persistent",
					"isAccordion": false,
					"widget": "boolean",
					"default": false,
					"name": "persistent",
					"exclude_level" : ['process'],
					"resume" : "If activated, the value of the memory is stored permanently. This persitent value will overwrite the value set in the authoring." 
				});
	
				globalConfig.children.push( {
					"type": "param",
					"title": "Local Storage",
					"isAccordion": false,
					"widget": "boolean",
					"default": false,
					"name": "localstorage",
					"exclude_level" : ['process'],
					"resume" : "If activated, the value of the memory is stored locally." 
				});	
			}

			setting.value.inspector.children.unshift(globalConfig);
		}
	}

	//@deprecated:Not used, only call in getMemoryOfProject
	getMemoryIDs( node ){
		if( !node )
			return;

		let ids = [];

		if( node.value.memory && node.value.memory.length > 0 ){
			for( let memory of node.value.memory ){
				ids.push( memory.id );
			}
		}
		return ids;
	}

	//@deprecated:Not used
	getMemoryOfProject( project ){
		return new Promise( function(resolve, reject){


			let memoryIDs = [];

			memoryIDs = memoryIDs.concat( this.getMemoryIDs( project ) );

			for( let xp of project.custom.children ){
				memoryIDs = memoryIDs.concat( this.getMemoryIDs( xp ) );

				if( xp.custom.children ){
					for( let screen of xp.custom.children ){
						memoryIDs = memoryIDs.concat( this.getMemoryIDs( screen ) );

						if( screen.custom.children ){
							for( let process of screen.custom.children ){
								memoryIDs = memoryIDs.concat( this.getMemoryIDs( process ) );
							}
						}
					}
				}
			}

			let url = this.main.config.baseURL+'_design/settings/_view/list-memory';
			Vue.http({url: url, method: 'GET', data: {keys:"[\""+memoryIDs.join('\",\"')+"\"]", group:true} }).then(function (response) {

				project.memory = [];
				for( var dataRow of response.body.rows ){

					let data = dataRow.value[0];
					if( !data.fields ){
						data.fields = [];
					}

					project.memory.push( { value : data , custom : {  } } );
				}

				resolve( project );

			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

			//APRÈS RÉCUPÉRATION DES MEMORY, CHECK IF SECRET ICI ET ON RÉCUPÈRE LA VALEUR QU'ON RÉASSIGNE AU BON FIELD VALUE


		}.bind(this));
	}

	// @deprecated: use helper.block.getMemoryById() instead
	// or remove the first argument and pass project node to helper
	// getMemoryByIDInProject( id )
	getMemoryByIDInProject(project, idMemory) {
		return helper.block.getMemoryById(project, idMemory);
	}


	triggerArrayColUpdated( memoryId ){
		this.emit('ArrayColUpdated', memoryId );
	}

}

export let memory = new MemoryStore();
