<!-- Bloc vue -->
<template lang="pug">

div.plug-bt(v-bind:class="[connexion.type, side, fieldname, {'selected':selected==fieldname+'_'+side}, {'deprecatedField':isDeprecated}]", v-on:click.stop="linkPlug(connexion.type)")

    span.value(v-if="side == 'input' && connexion.type!='state' && formatedVal !== null")
        span {{formatedVal}}
    span.name {{label | truncate(10) }}
    span.value(v-if="side == 'output' && connexion.type!='state' && formatedVal !== null")
        span {{formatedVal}}
    div.anchor


</template>

<script>

// STORE
import actionManager from '../../../../actions';
import * as helper from '../../../../helper';

export default {

	props: ['appState', 'connexion', 'fieldname', 'side', 'initialBloc' ],

	data() {
		return {
		}
	},

	mounted: function(){
	},

	destroyed : function(){
	},

	computed: {
      'bloc': function(){
            let bloc = helper.block.getBlockById( this.appState.project, this.connexion.id );

			let includedBlocks = [];
			if( bloc.value.includedBlocks ){
                let realBloc = null;
				bloc.value.includedBlocks.forEach( ( objId ) => {
					let bloc = helper.block.getBlockById( this.appState.project, objId );
                    if( bloc && bloc.custom.fields[this.fieldname] ){
                        realBloc = bloc;
                    }
				});
                bloc = realBloc ? realBloc : bloc;
			}

            return bloc ? bloc : null;
        },

        'field': function(){
            let currentType = this.connexion.type == 'field' ? 'fields' : 'states';
            let field = this.bloc.custom[currentType][this.fieldname];
            return field;
        },

        isDeprecated: function(){
          if(this.field.deprecated) return true;
        },

        'selected' : function(){
			if( this.appState.editor.linking.from ){
				let from = this.appState.editor.linking.from  ;
				if( from.blocID == this.bloc.value._id ){
					return from.name+'_'+from.side;
                }
			}
			return null;
		},

        'label': function(){

			let capitalizeFirstLetter = (string) => {
				return string.charAt(0).toUpperCase() + string.slice(1);
			};

			if (this.field) {
				let label = this.field.label;
				if( this.field.dynLabel !== undefined ){
					let targetField = helper.block.getField( this.bloc, this.field.dynLabel );
					if( targetField && targetField.value )
              		return capitalizeFirstLetter(targetField.value);
				}
				if(this.field.deprecated) return `${label} (depre.)`
					return label ? label : this.fieldname;
			} else {
					return '';
			}
        },

        'formatedVal': function(){
      			if (this.field) {
      				let val = this.field.value;
      				let formatedVal = this.field.formatedVal;
                return formatedVal ? formatedVal : val;
      			} else {
      				return '';
      			}
        },

	},

	methods:{
    linkPlug: function( type ){

			let subaction = 'from';

			if( this.appState.editor.linking.status )
				subaction = 'to';

			actionManager.trigger('editor:schematic:drawLink', { subaction : subaction , blocID : this.initialBloc.value._id, type: type, side : this.side , name : this.fieldname });

		},

	},

	filters: {
		truncate: function(string, value=10) {
			if( !string )
				return string;
			if( string.length <= value )
				return string;
			return string.substring(0, value) + '...';
		}
	}

}

</script>

<style lang="stylus">

</style>
