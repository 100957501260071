var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tagging" }, [
    _c("div", { staticClass: "meta" }, [
      _c("div", { staticClass: "dataTag" }, [
        _c("div", { staticClass: "infoTopic" }, [
          !_vm.tagModal
            ? _c("div", [
                _c("div", { staticClass: "subtitle" }, [_vm._v("File")]),
                _c("div", { staticClass: "clickableField" }, [
                  _c("input", {
                    attrs: { id: "filename", disabled: true },
                    domProps: { value: _vm.library.mediaPreview.filename },
                    on: {
                      change: function($event) {
                        $event.stopPropagation()
                        return _vm.saveFilename(_vm.library.mediaPreview._id)
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "clickableMask",
                      attrs: { id: "editname" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.editFilename()
                        }
                      }
                    },
                    [_vm._v("Edit name")]
                  )
                ]),
                _c("div", { staticClass: "clickableField" }, [
                  _c("input", {
                    attrs: { id: "urlToCopy" },
                    domProps: { value: _vm.library.mediaPreview.url },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.copyURL(_vm.library.mediaPreview.url)
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "clickableMask",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.copyURL(_vm.library.mediaPreview.url)
                        }
                      }
                    },
                    [_vm._v("Copy this URL")]
                  )
                ])
              ])
            : _c("div", { staticClass: "subtitle" }, [_vm._v("Tags")]),
          _c("div", { staticClass: "addTag" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newTag,
                  expression: "newTag"
                }
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.newTag },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addTag()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newTag = $event.target.value
                }
              }
            }),
            _c("input", {
              attrs: { type: "submit", value: "Add" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addTag()
                }
              }
            })
          ]),
          _c("div", { staticClass: "listTags" }, [
            _c(
              "ul",
              _vm._l(_vm.tags, function(tag, indexTag) {
                return _c("li", [
                  _c(
                    "span",
                    {
                      staticClass: "bt-delete",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteTag(indexTag)
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "quit" } })],
                    1
                  ),
                  _c("span", { staticClass: "text" }, [_vm._v(_vm._s(tag))])
                ])
              }),
              0
            ),
            _c("p", [_vm._v(_vm._s(_vm.error))]),
            _vm.tagModal
              ? _c(
                  "div",
                  {
                    staticClass: "button confirm confirmTags",
                    on: {
                      click: function($event) {
                        return _vm.confirmTags()
                      }
                    }
                  },
                  [_c("p", [_vm._v("Confirm Tags")])]
                )
              : _vm._e(),
            _vm.canEditJSON
              ? _c(
                  "div",
                  {
                    staticClass: "bt-jsonedit",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.editJSON()
                      }
                    }
                  },
                  [_vm._v("Edit JSON")]
                )
              : _vm._e(),
            !_vm.tagModal
              ? _c("div", { staticClass: "replaceFile" }, [
                  _c("input", {
                    staticClass: "file input-file",
                    attrs: { type: "file", id: "asset-replace-file" },
                    on: { change: _vm.uploadFile }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "input-file-trigger",
                      attrs: { for: "asset-replace-file" }
                    },
                    [_vm._v("Replace file")]
                  ),
                  _vm.library.status ==
                  _vm.library.statusList.LIBRARY_STATE_SELECT
                    ? _c(
                        "div",
                        {
                          staticClass: "bt-choose",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.chooseFile()
                            }
                          }
                        },
                        [_vm._v("Choose file")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "clear" })
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }