/*
	Project Store instance -
	all function to get info of Project
*/

import { EventEmitter } from 'events';
import uuid from 'uuid/v4';
import * as helper from '../helper';
import Vue from 'vue';
import VueResource from 'vue-resource';
import deepCopy from 'deepcopy';


Vue.use(VueResource);

class ProjectStore extends EventEmitter {
	constructor(){
		super();
	}

	setMain( main ){
		this.main = main;
		this.fieldFilter = new helper.FieldsFilter(this.main.defaultRef)
	}


	fillIDsByLevel( node, levelParam, level, ids ){

		if( !node )
			return;

		if( levelParam == "type" ){
			if( node.value.type == level ){
				ids.push( '"'+node.value._id+'"' );
			}
		}
		else if( levelParam == "level" ){
			if( node.value.level == level ){
				ids.push( '"'+node.value._id+'"' );
			}
		}

		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.fillIDsByLevel( subnode, levelParam, level, ids );
			}
		}

	}

	cleanCouchDBGroupIssue( values, tabsNode ){
		if( Array.isArray(tabsNode) && tabsNode.length > 0 ){
			for (var i = 0; i < tabsNode.length; i++) {
				this.cleanCouchDBGroupIssue( values , tabsNode[i] );
			}
		} else values.push( tabsNode );
	}

	getProjectRef( projectId , owner = null ){
		return new Promise( function(resolve, reject){

			let url = this.main.config.baseURLAuth+'_design/all/_view/list-all?key="ref_'+projectId+'"';

			let self = this;

			Vue.http.get( url ).then(function (response) {
				if (response.body && response.body.rows.length > 0 ){
					let refproject = response.body.rows[0];
					resolve( refproject );
				}
			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

		}.bind(this));
	}

	getAllDocsProject( id ){return new Promise( function(resolve, reject){
			// @todo: use "/_design/lists/_view/all-list" instead of "_all_docs"
			// to remove _design docs
			let url = this.main.config.baseURL+'_all_docs';
			let self = this;
			Vue.http({url: url, method: 'GET', params: { include_docs : true} }).then(function (response) {

				let alldocs = [];
				response.body.rows.forEach( row => {
					self.cleanCouchDBGroupIssue( alldocs , row );
				});

				let objs = [];
				objs["module"] = [];
				objs["screens"] = [];

				alldocs.forEach( data => {

					if( !data.doc.type )
						return;

					let doc = data.doc;
					if( !doc.fields )
						doc.fields = [];

					if( doc.type == "trigger" && !doc.events )
						doc.events = [];

					if( doc.type == "trigger" && !doc.states )
						doc.states = [];

					doc = { value : Object.assign( {}, doc) , custom : { children: [], objects: [] } };

					if( data.doc.type == "object" ){
						let tag = data.doc.format.substr(0, data.doc.format.indexOf('-'));
						doc.custom.tag = tag;
					}

					if( data.doc.ref_process && data.doc.type != "memory" ){ // to get all kinds of modules
						objs["module"].push( doc );
						return;
					}

					if( data.doc.ref_experience && data.doc.type != "memory" ){// to get screen, tapbar, navbar ...
						objs["screens"].push( doc );
						return;
					}

					if( !objs[data.doc.type] )
						objs[data.doc.type] = [];

					objs[data.doc.type].push( doc );

				});

				// init media list when fetching project data from db
				self.main.lib.setMedia(objs['media'] || []);
				resolve( objs );

			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

		}.bind(this));
	}

	/**
	 * divideIntoParents build the hierarchy of the project with the parent and child node.
	 * Allows also to identify the deprecated and buildOnly modules at the beginning of the loading of the project.
	 *
	 * @param  {Array} parentList The parent node
	 * @param  {Array} children   The child node
	 * @param  {String} link      Attribut of the link refering to the parent node.
	 * @param  {String} tab       Definition of the node, always "children".
	 * @return {Array}	The parent node.
	 */
	divideIntoParents( parentList, children, link , tab ){

		if( !parentList || parentList.length == 0 ) return;
		parentList.forEach( ( parent ) => {
			if( children ){
				children.forEach( ( child ) => {
					
					if( child.value[link] == parent.value._id ){

						let needFieldsRebuild = this.fieldFilter.fieldListReconstructor(child)

						if(link === "ref_process"){
							this.deprecated(child, this.main.config.deprecated)
							this.buildOnlyModules(child, this.main.config.buildOnlyModules)

							parent.custom[tab].push( needFieldsRebuild ? needFieldsRebuild : child);
						} else {
							// combler selon le niveau (en dessous de l'experience)
							parent.custom[tab].push( needFieldsRebuild ? needFieldsRebuild : child );
						}
					}
				})
			}
		});
	}

	/**
	 * deprecated populates the child bloc with the attribut deprecated. This only concerns the existing bloc module prior to the
	 * addition of the flag on the app side.
	 *
	 * @param  {Object} child             the block to be set in the hierarchy of the project
	 * @param  {Object} deprecated 		  the list of the deprecated modules
	 * @return {Object}                   the child with new the deprecated attribut.
	 */
	deprecated(child, deprecated){
		if(deprecated){
			Object.keys(deprecated).forEach(deprecatedModule => {
				if(deprecatedModule === child.value.type || deprecatedModule === child.value.format){
					return Object.assign(child.value, { deprecated: true })
				}
			})
		}
	}

		/**
	 * buildOnlyModules populates the child bloc with the attribut buildOnly. This only concerns the existing bloc module prior to the
	 * addition of the flag on the app side.
	 *
	 * @param  {Object} child             the block to be set in the hierarchy of the project
	 * @param  {Object} deprecated 		  the list of the deprecated modules
	 * @return {Object}                   the child with new the deprecated attribut.
	 */
	buildOnlyModules(child, buildOnly){
		if(buildOnly){
			Object.keys(buildOnly).forEach(buildOnlyModule => {
				if(buildOnlyModule === child.value.type || buildOnlyModule === child.value.format){
					return Object.assign(child.value, { buildOnly: true })
				}
			})
		}
	}

	/**
	 * divideSubObjectsIntoUiScreen links the uiObjects with their parents inside the custom object.
	 * Allows also to identify the deprecated objects at the beginning of the loading of the project.
	 *
	 * @param  {Array} objs		the objects of the project.
	 * @return {Object}       the child with new datas and the the parents with the new child uiObjects.
	 */
	divideSubObjectsIntoUiScreen( objs ){
		if( !objs || objs.length == 0 ) return;
		
		
		objs.forEach( ( obj ) => {

			let parent = this.main.objects.getParentOfUIBlock( objs , obj ); // ObjectsStore.js
			
			// check fields declaration and rebuild
			let needFieldsRebuild = this.fieldFilter.fieldListReconstructor(obj) 

			if( parent ){
				this.deprecated(obj, this.main.config.deprecated)
				obj.custom.subobj = true;
				obj.custom.uiParent = parent.value._id;
				parent.custom.objects.push( needFieldsRebuild ? needFieldsRebuild : obj);
			}

		});
	}


	getUserSecrets(user){
		return new Promise((resolve, reject) => {
			this.main.user.getUser(user)
			.then((response) => {
				resolve(response.secrets)
			}).catch((error) => {
				reject(error);
			})
		})
	}


	getProject( id, user ){
		return new Promise(function(resolve, reject){

			let self = this;
			let projectJSON = null;

			let proj = null;

			self.getAllDocsProject( id ).then( async function( objs ){

				proj = objs['project'][0];

				self.divideIntoParents( objs['process'] , objs['module'] , 'ref_process', 'children' );
				self.divideIntoParents( objs['screens'] , objs['process'] , 'ref_screen', 'children' );
				self.divideIntoParents( objs['screens'] , objs['trigger'] , 'ref_screen', 'children' );
				self.divideIntoParents( objs['screens'] , objs['module'] , 'ref_process', 'children' );

				self.divideIntoParents( objs['screens'] , objs['process'] , 'ref_widget', 'children' );
				self.divideIntoParents( objs['screens'] , objs['trigger'] , 'ref_widget', 'children' );


				self.divideSubObjectsIntoUiScreen( objs['object'] );

				let mainobjs = [];
				if( objs['object'] && objs['object'].length > 0 ){
					mainobjs =  objs['object'].filter( ( obj ) => {
						if( !obj.custom.subobj ) return obj;
						return null;
					});
				}


				self.divideIntoParents( objs['screens'], mainobjs , 'ref_block', 'objects' );

				self.divideIntoParents( objs['experience'] , objs['screens'] , 'ref_experience', 'children' );

				proj.custom.children = objs['experience'] ? objs['experience'] : [];
				proj.memory = objs['memory'] ? objs['memory'] : [];

				const missingSecretMemories = [];
				let projectHasSecrets = false;
				
				proj.memory.forEach((mem) => {
					if (mem.value.format === 'secret') {
						projectHasSecrets = true;
					}
					// cast if necessary the given widget name to the appropriate data type
					// (for compatibility with older projects that have a bad widget name)
					if (mem.value.format === 'array') {
						const fields = mem.value.fields.filter((f) => typeof f.header === 'object');
						fields.forEach((f) => {
							f.header.forEach((h) => {
								if (h.widget && h.widget.toLowerCase() === 'json' || h.widget === 'JSONType') {
									if (h.widget.toLowerCase() === 'json')
										console.warn(`Cast invalid widget name in memory '${mem.value.name}':`, h.widget);
									h.widget = 'jsontype';
								}
							});
						});
					}
				});
	
				try {
					if (user && projectHasSecrets) {
						await self.getUserSecrets(user)
						.then((secrets) => {
							if (secrets) {
								proj.memory.forEach((mem) => {
									if (mem.value.format === 'secret' && Object.keys(secrets).length > 0) {
										if (secrets[mem.value._id] && secrets[mem.value._id].value) {
											mem.value.fields[1].value = secrets[mem.value._id].value;
										} else {
											missingSecretMemories.push(mem.value.name);
											mem.value.fields[1].value = 'Value not available';
										}
									}
								});
							} else {
								proj.memory.forEach((mem) => {
									if (mem.value.format === 'secret') {
										missingSecretMemories.push(mem.value.name);
										mem.value.fields[1].value = 'Value not available';
									}
								});
							}
					
							if (missingSecretMemories.length > 0) {
								proj['secretMemoriesNotFound'] = missingSecretMemories;
							}
						})
						.catch((error) => {
							console.error(`Fail to get user\'s secrets with error: `, error);
						});
					}

					proj.templates = objs['template'] ? objs['template'] : [];
					proj.sessions = objs['session-set'] ? objs['session-set'] : [];

					let mediaList = objs['media'] ? objs['media'] : [];
					self.main.lib.listMedia = mediaList;

					const authConfig = await self.main.authoring.getAuthoringConfig(); // Get authoring config for safe
					return authConfig;
				} catch(error) {
					console.error('error when getting project: ', error)
				}
			})
			.then( function( authoringConfig ){
				if(proj && authoringConfig) resolve( proj );
			});

			return;

			/*
			//let test =  self.main.projects.getProjectFromTab( id ).then( function( project ){
			let test = self.getProjectNode( id ).then( function( project ){

				if( project == null ){
					reject(Error('NoAccess')); // Pas accès a ce projet
					return null;
				}

				return self.getChildrenOfNodes( project , 'list-experience' , 'type', 'project' ); // get experiences
			})
			.then( function( project ){
				if(project)
					return self.getChildrenOfNodes( project, 'list-screen', 'type', 'experience' ); // Get screens
			})
			.then( function( project ){
				if(project)
					return self.getChildrenOfNodes( project, 'list-graph' , 'type', 'screen' ); // Get graphs
			})
			.then( function( project ){
				if(project)
					return self.getChildrenOfNodes( project, 'list-modules' ,  'type', 'process' ); // Get modules
			})
			.then( function( project ){
				if(project)
					return self.main.objects.getObjectsOfProject( project ); // Get objects of project
			})
			.then( function( project ){
				if(project)
					return self.main.memory.getMemoryOfProject( project ); // Get memory of project
			})
			.then( function( project ){
				if(project){
					projectJSON = project;
					return self.main.authoring.getAuthoringConfig(); // Get authoring config for safe
				}
			})
			.then( function( authoringConfig ){
				if(projectJSON && authoringConfig)
					resolve( projectJSON );
			});
			*/

		}.bind(this));
	}

	// The original code is moved in helper -> cleanNode()
	clearProject( node, deleteList ){
		helper.block.cleanNode(node, deleteList);

		// Also clear _deleted medias (we don't want them in the next save)
		// @todo: this could be done from the 'DeleteMedia' action without saving the entire project
		// and reduce save process latency (double loop here)
		if (Array.isArray(deleteList) && deleteList.length > 0) { 
			deleteList.forEach((id) => {
				this.main.lib.listMedia.forEach((media, index) => {
					if (media._id === id) {
						this.main.lib.listMedia.splice(index, 1);
						// delete on asset server
						const form = new FormData();
						form.append('action', 'delete');
						form.append('asset', media.url.replace(/^.*\/upload\//, ''));
						
						this.main.lib.deleteMedia(media)

						// this.main.lib.sendToServer(form).then(
						// 	response => {
						// 		if (response.error) {
						// 			alert(`Fail to delete the file '${media.filename}' from server:\n${response.error}`);
						// 		}
						// 	},
						// 	error => alert(`Fail to delete the file '${media.filename}' from server.`)
						// );

					}
				});
			});
		}
	}

	slugify(text) {
		return text.toString().toLowerCase()
	    .replace(/\s+/g, '-')           // Replace spaces with -
	    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
	    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
	    .replace(/^-+/, '')             // Trim - from start of text
	    .replace(/-+$/, '');            // Trim - from end of text
	}

	isUsedSlug( slug, parent ){
		let iterable = [];

		if (Array.isArray(parent)) {
			iterable = parent;
		} else {
			if( parent && parent.custom && parent.custom.children ){
				iterable = parent.custom.children;
			} else return;
		}

		if( iterable.length > 0 ){
			for( let node of iterable ){
				if( node.value.slug && node.value.slug == slug ) return true;
			}
		}

		return false;
	}

	isUsedProjectSlug( slug, project, user ){

		return new Promise( function(resolve, reject){

			let owner = project.currentUserType == "owner" ? user.replace(':', '%3A') : false
			let url = `${this.main.config.baseURLAuth}_design/slug/_view/project?key=["${slug}","${owner}"]`
			let self = this;

			Vue.http.get( url ).then(function (response) {
				if (response.body.rows.length > 0 ){
					resolve(true);
				}
				else{
					resolve(false);
				}
			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

		}.bind(this));

	}

	parseProjectForUpdate( dataTab , deleteList , node , parent ){

		node.value.updated = (new Date()).getTime();// On met à jour la date d'update du noeud

		if( node.value.slug == null && node.value.type != "memory" ){ // On vérifie la valeur du slug

			let slug = this.slugify( node.value.name );

			let slugFull = slug+"";
			let occurence = 1;
			while( this.isUsedSlug( slugFull, parent ) ){
				occurence++;
				slugFull = slug+"-"+occurence;
			}

			node.value.slug = slugFull;

		}

		let fieldsList = node.value.fields
		let finalFieldsList = []
		let nType = node.value.type, nFormat = node.value.format, nName = node.value.name;

		if (fieldsList && nType && nType != "memory" && node.value.level !== "experience") {

			let blockIncluded = node.value.includedBlockType ? node.value.includedBlockType[0] : false; // Handle Ui Block Type
	
			for (var i = fieldsList.length - 1; i >= 0; i--) {
	
				if ( fieldsList[i].name == "mapLibrary") this.fieldFilter.refs.mapLibrary = fieldsList[i].value
	
				if (nType == "experience" || node.value.level == "project") finalFieldsList.unshift(fieldsList[i])
				else if(fieldsList[i].name !== undefined && fieldsList[i].value !== undefined) {
										
					if (this.fieldFilter.checkDefaultValue(fieldsList[i], nType, nFormat, blockIncluded, nName) ) {
						finalFieldsList.unshift(fieldsList[i]) // Push the field node
					}
				} else finalFieldsList.unshift(fieldsList[i]) // Those fields should not be saved !
			}
			if (finalFieldsList.length > 0) node.value.modifications = finalFieldsList
		}
		
		// On ajoute les nouvelles valeurs à mettre à jour
		dataTab.push( JSON.parse(JSON.stringify(node.value)) );
		
		// Si le noeud est a supprimé on l'ajoute au tab pour rafraichir la vue
		if( node.value._deleted ) deleteList.push( node.value._id );

		// On appelle récursivement les enfants
		if( node.custom && node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.parseProjectForUpdate( dataTab, deleteList, subnode, node);
			}
		}

		// si il y a des memory
		if( node.memory ){
			for( let memory of node.memory ){
				this.parseProjectForUpdate( dataTab, deleteList, memory, parent);
			}
		}

		// si il y a des sessions
		if( node.sessions ){
			for( let session of node.sessions ){
				this.parseProjectForUpdate( dataTab, deleteList, session, node.sessions);
			}
		}

		// On appelle récursivement les objects enfants
		if( node.custom && node.custom.objects && node.custom.objects.length > 0 ){
			for( let object of node.custom.objects ){
				this.parseProjectForUpdate( dataTab, deleteList, object, node);
			}
		}
	}

	/**
	 * Update the current project reference.
	 * @param {Object} node - The current node to be saved.
	 */
	updateProjectRef(node) {
		// always update the project modification date
		this.main.config.openProject.value.updated = (new Date()).getTime();
		
		// depending on where you change the project name, the slug and the project name are not updated
		// basically when the inspector is not open.
		if (node.value.type === 'project') {
			this.main.config.openProject.value.slug = node.value.slug;
			this.main.config.openProject.value.name = node.value.name;
		}
	}

	/**
	 * Copy the project reference to the authoring database.
	 * @param {Object} project - The current project node.
	 */
	saveProjectRef(project) {
		const toCopy = ['name', 'slug', 'updated'];

		// The cover image is also copied so that it can be displayed in the project list.
		const cover = this.getProjectImageURL(project, 'cover');
		const data = { 'needUpdate': true, cover };

		toCopy.forEach((field) => {
			data[field] = project.value[field];
		});

		const url = this.main.config.baseURLAuth+'_design/update/_update/inplace/ref_'+project.value._id;
		Vue.http.post(url , data).then(
			response => {},
			error => {
				// @todo: implement notification
				//this.trigger('notification', { message: "Failed to update project document reference.", type: 'error' });
			}
		);
	}

	isUsedProjectName(projectName, user, type){
		return new Promise( function(resolve, reject){

			let url = `${this.main.config.previewURL}authoring/checkNameSlug`;
			let info = {
				name: projectName,
				owner: user,
				type: type
			}

			Vue.http.post( url, info).then(function (response) {
				if (response.body == false || response.body == true) resolve(response.body)
			}, function (error) {
				reject( error );
			});
		}.bind(this));
	}

	async duplicateProject(reqParams){

		let url = `https:${this.main.config.previewURL}authoring/duplicate/`
		let dupliObj = reqParams
		dupliObj.newDBInfo.slug = this.slugify(dupliObj.newDBInfo.name)

		// Need to check if Project Name is already used.
		let projectNameUsed = await this.isUsedProjectName(dupliObj.newDBInfo.name, dupliObj.newDBInfo.owner, "name")

		let self = this

		return new Promise( (resolve, reject) => {

			if (!projectNameUsed) {
				Vue.http.post( url, dupliObj).then( (response) => {
					if (response.ok ) {
						self.main.projects.getProjects({ forceRefresh : true } ).then( () => {
							resolve(response.body.statusText)
						});
					}
				}, function (error) {
					console.warn('notification', { type: 'warning', message: error });
					self.main.state.modal.duplicateError = "Error during duplication of the project."
					reject(error)
				})
			} else {
				console.warn('notification', { type: 'warning', message: "Name already used" });
				self.main.state.modal.duplicateError = "This name is already used by another Project."
				reject("Name already used")
			}
		})
		
	}
				

	saveProject( nodeToSave, callback, isProject = true, user){

		this.emit('projectStartSaving', {});

		let data = new Object();
		data.docs = [];

		let deleteList = [];

		this.parseProjectForUpdate(data.docs, deleteList, nodeToSave, this.projects);
		this.updateProjectRef(nodeToSave);

		// update media data (for old docs) and add media to bulk save
		let mediaList = this.main.lib.listMedia;
		mediaList.forEach( ( media ) => {
			// update old doc to include missing props
			// @todo: code below will be dead in one or two years,
			// when all old projects are saved again
			media = helper.media.updateDocument(media, nodeToSave.value._id, user);
			// add to bulk save
			if (!media.usedby || (media.usedby.projects && media.usedby.projects.indexOf(nodeToSave.value._id) != -1)) {
				if(media.mediaAddedFrom === nodeToSave.value._id){
					if (media._deleted) deleteList.push(media._id);
					data.docs.push( media );
				}
			}
		})


		let url = this.main.config.baseURL+'_bulk_docs';
		let self = this;

		this.saveProjectRef(self.main.config.openProject);
		this.linkCoversAndIconsToProject(self.main.config.openProject);

		// SECRET MEMORIES MANAGEMENT - part 1
		//1. Deepcopy of the docs before the remplacement of the value of the secret memory by its _id
		//   in order to be able to keep the correct value while working on the opened project.
		//2. Check in the docs if memory and memory secret, and store the data with a flag if deleted.
		//3. Replace the value by the _id for secured storage in the memory document in the database before saving the project.
		//4. Send the secretsMemories to userStore.

		const backup = deepCopy(data.docs);
		const secretMemoryDocs = {};
		
		data.docs.forEach(doc => {
			// filter Unmodified fields
			if (doc.modifications) {
				doc.fields = doc.modifications
				delete doc['modifications']
			}
			//filter for buildOnly flag : should not be saved in DB.
			if(doc.buildOnly) delete doc["buildOnly"]

            if (doc.type === 'memory' && doc.format === 'secret') {
				const memoryId = doc._id;
				const secretValue = doc.fields[1].value;
				
				secretMemoryDocs[memoryId] = {
					value: secretValue,
				};
				
				if (doc._deleted) {
					secretMemoryDocs[memoryId].deleted = true;
				} else {
					doc.fields[1].value = doc._id;
				}
            }
		});

		if (Object.keys(secretMemoryDocs).length > 0 && user) {
			this.main.user.addSecretsToUserDB(user, secretMemoryDocs);
		}


		/** DATABASE WORKSHOP NS-286
		 * ! this call should have an authentification token and pass by the backend server
		 * ! nothing is secure and every user could do this call
		 * ! We should use the token/cookie used with couchDB login.
		*/
		Vue.http.post( url , data ).then(
			response => {

				// Suppression des id deleted
				self.clearProject(nodeToSave, deleteList);

				// update rev
				for (let rev of response.body) {
					// notify errors
					if (rev.error) {
						let bloc = self.main.block.getEntityByIDInWholeProject(rev.id);
						const bname = bloc ? (bloc.value ? bloc.value.name : bloc.filename) : 'unknown';
						const btype = bloc ? (bloc.value ? bloc.value.type : bloc.type) : 'unknown';
						const msg = `Error while saving ${btype} '${bname}' [${rev.id}]: ${rev.reason}`;
						// @todo: remove warning for media while the ticket NS-472 is fully treated
						if (btype === 'media') console.warn('notification', { type: 'warning', message: msg });
						else callback({ code: -1, message: msg });
						// @todo: uncomment while all conflicts can be treated
						//return; // stop on error
					}

					// Check if the node to save is a normal node and update its rev
					let entity = self.main.block.getEntityByIDInProject(nodeToSave, rev.id);

					if (entity) entity.value._rev = rev.rev;
					else {
						// If the node to save is not a normal node, maybe it's a memory?
						const memory = self.main.memory.getMemoryByIDInProject(nodeToSave, rev.id);
						if (memory) memory.value._rev = rev.rev

						// Maybe it's a media?
						const media = self.main.lib.getLoadedMediaByID(rev.id);
						if (media) media._rev = rev.rev;

						// Maybe it's a session-set?
						const session = helper.block.getSessionById(nodeToSave, rev.id, false);
						if (session && session.value) session.value._rev = rev.rev;
					}
				}
				
				// @todo: to be removed ! wwhy we register the current node (Experience, Screen, Process)
				// as current project?
				// self.main.config.openProject = nodeToSave;
				self.emit('projectSaved', { project: self.main.config.openProject });
				
				// SECRET MEMORIES MANAGEMENT - part 2
				// With the backup, restore the correct value of the memory for the opened project
				data.docs.forEach(doc => {
					if (doc.format === 'secret') {
						backup.forEach((backupDoc) => {
							if (doc._id === backupDoc._id) {
								doc.fields[1].value = backupDoc.fields[1].value;
							}
						});
					}
				});

				callback();

			},
			error => callback({ code: error.status || -1, message: error.statusText || "Error while saving project" })
		)
	}

	generateProjectJSON(reject) {
		// Currently generate every Xp, should be better to flag the modifications to regenerate only modified experiences.
		let proms = this.main.state.project.custom.children.map((xp) => {
			const url = this.main.config.previewURL + 'generateDataProjectFile/' + helper.project.getPlayerUrl(this.main.state, xp.value._id) + "?cache=false";
			return Vue.http.get(url, {}).then(
				response => {},
				error => reject({ code: error.status || -1, message: error.statusText || "Error while generating project JSON" })
			);
		});

		return Promise.all(proms).then(() => {});
	}

	// recursive function go through the whole project and to fill tabIds with each block _id
	getAllIDsOfProject( tabIds , node ){
		if( node.value && node.value._id )
			tabIds.push( node.value._id );

		if( node.custom && node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.getAllIDsOfProject( tabIds, subnode );
			}
		}
	}

	dispatchInspectorInfo( currInspector ){
		this.emit('inspectorUpdated', {inspectorID:currInspector});
	}

	dispatchWaitInfo( status ){
		this.emit('waitStatusUpdate', {isWaiting:status});
	}

	createSession() {

		let sessionName = "session X",
			sessionIndex = 1;

		if (this.main.config.openProject.sessions && this.main.config.openProject.sessions.length > 0) {
			let listSessions = this.main.config.openProject.sessions.map((s) => !s.value._deleted && s.value.name);
			while (listSessions.indexOf(sessionName.replace("X", sessionIndex)) !== -1) {
				sessionIndex++;
			}
		}

		return {
			custom: {},
			value: {
				_id: uuid(),
				type: "session-set",
				name: sessionName.replace("X", sessionIndex),
				slug: this.slugify(sessionName.replace("X", sessionIndex)),
				fields: [
					// {
					// 	name: "maxClient",
					// 	value: Infinity
					// },{
					// 	name: "duration",
					// 	value: 60
					// },{
					// 	name: "schedulingMode",
					// 	value: 'permanent'
					// },{
					// 	name: "startTime",
					// 	value: Date.now()
					// },{
					// 	name: "endTime",
					// 	value: Date.now()
					// },{
					// 	name: "every",
					// 	value: 60
					// }
				]
			}
		}
	}

	checkSessionSlugUniqness(slug) {
		let listSessions = this.main.config.openProject.sessions.map((s) => s.slug);
		return listSessions.indexOf(slug) === -1;
	}

	updateSession( session, params ) {
		delete params._id;
		if (this.checkSessionSlugUniqness(params.slug)) {
			return Object.assign(session, params);
		}
	}

	createDBProject( id ){
		return new Promise( function(resolve, reject){

			let url = this.main.config.previewURL+'authoring/createdb/nodal-project-'+id;
			Vue.http.get( url ).then(function (response) {
				if (response.body.error === null )
					resolve(response.body );
				else
					reject(response.body.error );
			}, function( error ){
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

		}.bind(this));
	}

	deleteDBProject( id ){
		return new Promise( function(resolve, reject){
			let url = this.main.config.previewURL+'authoring/destroydb/nodal-project-'+id;
			Vue.http.get( url ).then(function (response) {
				resolve( response );
			});
		}.bind(this));
	}

	/**
	 * Looks for covers and icons in the project-level inspector.
	 */
	getProjectImageURL(project, type) {
		if (!project.value.modifications) return null;

		/**
		 * @note The modifications array always contains all the fields, even
		 * 	if one of them hasn't been modified. If this is a bug, please note
		 * 	that the linkCoversAndIconsToProject() method behviour might be
		 *  impacted by a future fix.
		 */
		const field = project.value.modifications.find((field) => {
			return field.name === type;
		});

		if (field && field.value) {
			const results = this.main.lib.findById(field.value);
			
			if (results.length) {
				return results[0].doc.url;
			}
		}

		return null;
	}

	/**
	 * Creates or updates symlinks on the asset server that point to the 
	 * different covers and icons selected for the Project (cover, favicon,
	 * og and touchicon). They are stored in a way so that their URLs can
	 * be easily retrieved by nodal-app server and rendered from the Ejs
	 * template, eg: [assetServerURL]/upload/[projectID]/favicon.
	 * 
	 * @param {object} project The project containing the images to link.
	 * @return {Promise} Resolves with a Promise.allSettled array containing
	 * 	the result of each post (fulfilled w/ a value or rejected w/ a reason).
	 */
	linkCoversAndIconsToProject(project) {
		const types = ['cover', 'favicon', 'og', 'touchicon'];
		const promises = [];

		types.forEach((type) => {
			const serverURL = this.main.config.assetURL;
			const url = this.getProjectImageURL(project, type);
			const data = new FormData();

			promises.push(new Promise((resolve, reject) => {
				if (url) {
					const fileName = url.replace(/^.*\/upload\//, '');
	
					data.append('action', 'link');
					data.append('pid', project.value._id);
					data.append('name', type);
					data.append('asset', fileName);
				} else {
					// Deletes the symlink if it exists but is not needed anymore. 
					data.append('action', 'unlink');
					data.append('asset', `${project.value._id}/${type}`);
				}

				Vue.http.post(serverURL, data, { crossOrigin: true })
				.then((response) => {
					resolve(response.body);
				}, (error) => {
					console.warn(`Failed to udpate symlink in asset server.`, error);
					reject(error);
				});
			}));
		});

		return Promise.allSettled(promises);
	}
}

export let project = new ProjectStore();
