/*
	Library Store instance -
	all function to get info of Library
*/

import { EventEmitter } from 'events';
import Vue from 'vue';
import VueResource from 'vue-resource';
import * as helper from '../helper';

Vue.use(VueResource);

class LibraryStore extends EventEmitter {
	constructor(){
		super();

		this.LIBRARY_STATE_CONSULT = "Library::Consultation";
		this.LIBRARY_STATE_SELECT = "Library::Selection";

		this._listMedia = [];

		this.types = {
			'image': ['png', 'jpg', 'gif', 'jpeg'],
			'video': ['mp4', 'mov', 'webm', 'ogg', 'ogv'],
			'sound': ['mp3', 'wav', 'ogg', 'oga', 'flac'],
			'file' : ['others'],
			'gesture' : ['xmmjson']
		}

		this._listMedia = [];

	}

	setMain( main ){
		this.main = main;
	}


	processMediaDocFromCouch(doc) {
		const path = doc.url ? new URL(doc.url).pathname : '';
		let assetType = 'file';

		for( var type in this.types ) {
			this.types[type].forEach( (ext) =>{
				if (path.toLowerCase().search("." + ext) != -1) assetType = type;
			});
		}

		return Object.assign({ tags: [] }, doc, { type: 'media', assetType });
	}

	findById(id) {
		let resultSet = this._listMedia.filter( (m) => {
			return m._id === id
		});

		resultSet = resultSet.map( (rsm) => {
			let idx = this.listMedia.findIndex( (m) => m._id === rsm._id );
			return {_id: rsm._id, index: idx, doc: rsm};
		});

		return resultSet;
	}

	// Old
	setMedia(_listMedia) {
		this._listMedia = [];
		_listMedia.forEach( m => {
			this._listMedia.push(this.processMediaDocFromCouch(m.value));
		});
	}

	addMedia( media ){
		if( this.getLoadedMediaByID( media._id ) === null ) {
			this._listMedia.push( media );
		}
	}

	set listMedia( _listMedia ){
		this._listMedia = [];
		_listMedia.forEach( m => {
			this._listMedia.push(this.processMediaDocFromCouch(m.value));
		});
	}

	get listMedia( ){
		return this._listMedia;
	}


	getMedia( user ){
		return new Promise( function(resolve, reject){;

			// let url = this.main.config.baseURL+'_design/settings/_view/list-media';
			let self = this;
			// Vue.http({url: url, method: 'GET'}).then(function (response) {

			// 	self._listMedia = [];
			// 	if( response.body.rows && response.body.rows.length > 0 ){

			// 		var datas = response.body.rows[0].value;
			// 		self.main.project.cleanCouchDBGroupIssue( self._listMedia , datas );

			// 		// Set type to media
			// 		for (var i = 0; i < self._listMedia.length; i++) {

			// 			let url = self._listMedia[i].url.replace(self.main.config.assetURL, '');
			// 			let currentType = 'file';

			// 			for( var type in self.types ) {
			// 				self.types[type].forEach( (extension) =>{
			// 					if( url.search("."+extension) != -1 )
			// 						currentType = type;
			// 				});
			// 			}

			// 			self._listMedia[i].assetType = currentType;

			// 			// if no tags, init array
			// 			if( !self._listMedia[i].tags )
			// 				self._listMedia[i].tags = [];

			// 		}
			//	}

				resolve( self._listMedia );
			// });

		}.bind(this));
	}

	getLoadedMediaByID( id ){
		// La lib a déjà été chargée juste avant, on cherche dans la liste courante
		if( this._listMedia && this._listMedia.length > 0 ){
			for (var i = 0; i < this._listMedia.length; i++) {
				if( this._listMedia[i]._id == id && this._listMedia[i]._deleted !== true )
					return this._listMedia[i];
			}
		}
		return null;
	}

	getMediaByID( id ){
		return new Promise( function(resolve, reject){

			// La lib a déjà été chargée juste avant, on cherche dans la liste courante
			let loadedMedia = this.getLoadedMediaByID( id );
			if( loadedMedia ) resolve(loadedMedia);

			let url = id.includes("https://") ? id : this.main.config.baseURL + id;

			Vue.http({
				url: url, method: 'GET', crossOrigin: true
			}).then(function (response) {
				if( response.body.error ) resolve( null );
				else resolve( response.body );
			}, function (error) {
				//@todo: contextualiser l'erreur dans le resolve [ticket NS-412]
				console.error("getMediaByID Error:", id, error);
				resolve( null );
			});

		}.bind(this));
	}

	/**
	 * Sent request to the assets server.
	 * @param {FormDat} dataForm — The data to be sent to the server
	 * @returns {Promise}
	 */
	sendToServer(dataForm) {
		return new Promise( function(resolve, reject){
			let url = this.main.config.assetURL;
			Vue.http.post(url, dataForm, { crossOrigin: true }).then(
				response => resolve(response.body),
				error => reject()
			);
		}.bind(this));
	}


	filenameExists( filename ){
		for (var i = 0; i < this._listMedia.length; i++) {
			if( this._listMedia[i].filename == filename )
				return true;
		}
		return false;
	}


	postFile( filename, data, target = null, addedFrom ){
		return new Promise( function(resolve, reject){

			let parts = filename.split('.');
			let ext = parts.splice(-1).join();
			let name = parts.join(".");
			let occurenceSuffix = "";
			let occurence = "";

			while( this.filenameExists( name+occurenceSuffix+occurence+"."+ext ) ){
				occurenceSuffix = "_";
				if( occurence == "" )
					occurence = 2;
				else
					occurence++;
			}

			let self = this;
			this.sendToServer(data).then(function (media) {

				if (media.error) throw new Error(media.error);

				media.filename = name+occurenceSuffix+occurence+"."+ext;
				media.user = helper.user.formatUserName(self.main.state.project.owner || self.main.state.user);
				media.mediaAddedFrom = addedFrom;
				media.mimeType = media.type; // media.type will be overridden by 'media'

				// remove request 
				delete media.code;
				delete media.message;
				if (!target) return self.main.block.createNewDoc( media, false );
				else return Object.assign(self.findById(target._id)[0].doc, media, {type: 'media'});
			})
			.then( function( media ){
				if (!target) {
					self._listMedia.push(self.processMediaDocFromCouch(media.value));
				}
				else{
					media._id = target._id;
					media._rev = target._rev;
					let mediaSet = self.findById(target._id);
					if (mediaSet.length) {
						self._listMedia[mediaSet[0].index] = media;
					}
				}
				resolve(media);
			}).catch((e) => {
				alert(`Sorry,\nan error occurred while saving the file '${filename}':\n${e.message}`);
			});
		}.bind(this));
	}


	/**
	 * Definitely delete a media from the database and the (assets) server.
	 * @param {Object} media - The media to be deleted
	 * @return {Promise}
	 */
	deleteMedia(media){
		return new Promise( (resolve, reject) => {
			let mediaSet = this.findById(media._id);

			if (mediaSet.length) {
				const url = this.main.config.baseURL + '_bulk_docs';
				const form = new FormData();

				// mark document to be deleted
				media._deleted = true;
				// define form data for deletion
				form.append('action', 'delete');
				form.append('asset', media.url.replace(/^.*\/upload\//, ''));

				// We should order the media by folder named using their userName
				// so we could easily check if the media is from/used by another project

				// delete from couchdb then from asset 
				// only if this media is not used by any other duplicated projects.
				if (media.usedBy.projects.length == 1 || media.usedBy.templates.length == 1) {
					Vue.http.post(url, { docs: [media] }).then(
						response => {
							this._listMedia.splice(mediaSet[0].index, 1);
							this.sendToServer(form).then(
								response => {
									if (response.error) alert(`Fail to delete the file '${media.filename}' from server:\n${response.error}`);
									resolve();
								},
								error => alert(`Fail to delete the file '${media.filename}' from server.`)
							);
						},
						error => alert(`An error occurred while deleting the file '${media.filename}.`)
					).finally( () => {
						// update all other media documents from other project using the media

						/** DATABASE WORKSHOP NS-324
						 * ! this call should have an authentification token
						 * ! nothing is secure and every user could do this call
						 * ! We should use the token/cookie used with couchDB login.
						 */
						const url = `https://${env.domain_app}/authoring/updateMediaDocs/`;
						let options = {
							projectsList: media.usedBy.projects.join(','),
							mediaID: media._id
						}

						const updateMediaDocs = async (url) => {
							await Vue.http.post(url, {params:options}).then( (res, err) => {
							})
							.catch( (err) => {
								console.warn("err on updateMediaDocs", err)
							})
						}
						updateMediaDocs(url);
					})
				}
			}
		});
	}

	updateMedia(media){
		media = media.value ? media.value : media;
		return new Promise( function(resolve, reject){
			let mediaSet = this.findById(media._id);
			if (mediaSet.length) {
				let url = this.main.config.baseURL+'_bulk_docs';
				let data = { docs : [media] };

				Vue.http.post( url , data ).then( (response) => {
					this._listMedia[mediaSet[0].index] = media;
					media._rev = response.body[0].rev;
					resolve(media);
				});
			}
		}.bind(this));
	}

	// update multiple media
	// @ Param Array List of documents

	updateMedias(mediaList){
		let medias = mediaList;
		return new Promise( function(resolve, reject){

			let url = this.main.config.baseURL+'_bulk_docs';
			let data = { docs : medias };
			/**
			 * DATABASE WORKSHOP NS-286
			 */
			Vue.http.post( url , data ).then( (response) => {

				//updates rev for every new media.
				medias.forEach( (media, index) => {
					response.body.forEach( (mediasRes) => {
						if (mediasRes.id == media._id) {
							let mediaSet = this.findById(media._id);
							this._listMedia[mediaSet[0].index] = media;
							media._rev = mediasRes.rev;
						}
					})
				})
				resolve();
			});
		}.bind(this));
	}

	broadcastFileSelected(field, file){
		this.emit('fileSelected', {field:field, file:file});
	}

	/**
	 * Register the media owner.
	 * @param {string} id - The media ID.
	 * @param {Object} workspace - The current media workspace.
	 */
	addMediaOwner(id, workspace) {

		let media = this.findById(id);
		let type = workspace.target + 's';

		if (media.length) {
			if (!media[0].doc.usedby) media[0].doc.usedby = {};
			if (!media[0].doc.usedby[type]) media[0].doc.usedby[type] = [];
			if (media[0].doc.usedby[type].indexOf(workspace.id) == -1) media[0].doc.usedby[type].push(workspace.id);
		}
	}

}

export let lib = new LibraryStore();
