<template lang="pug">
div.tagging
    div.meta
        div.dataTag
            div.infoTopic
                div(v-if="!tagModal")
                    div.subtitle File
                    div.clickableField
                        input#filename(:value="library.mediaPreview.filename", :disabled="true", v-on:change.stop="saveFilename(library.mediaPreview._id)")
                        div.clickableMask#editname(v-on:click.stop="editFilename()") Edit name
                    div.clickableField
                        input#urlToCopy(:value="library.mediaPreview.url", v-on:click.stop="copyURL(library.mediaPreview.url)")
                        div.clickableMask(v-on:click.stop="copyURL(library.mediaPreview.url)") Copy this URL

                div.subtitle(v-else) Tags

                div.addTag
                    input(type="text", v-model="newTag", v-on:keyup.enter="addTag()")
                    input(type="submit", value="Add", v-on:click.stop="addTag()")
                div.listTags
                    ul
                        li(v-for="(tag,indexTag) of tags")
                            span.bt-delete(v-on:click.stop="deleteTag(indexTag)")
                                icon(name="quit")
                            span.text {{tag}}
                    p {{error}}

                    div.button.confirm.confirmTags(v-if="tagModal", v-on:click="confirmTags()")
                        p Confirm Tags

                    div.bt-jsonedit(v-if="canEditJSON", @click.stop="editJSON()") Edit JSON

                    div.replaceFile(v-if="!tagModal")
                        input.file(type="file", id="asset-replace-file", @change="uploadFile", class="input-file")
                        label(for="asset-replace-file", class="input-file-trigger") Replace file

                        div.bt-choose(v-if="library.status==library.statusList.LIBRARY_STATE_SELECT", v-on:click.stop="chooseFile()") Choose file
                        div.clear
</template>

<script>
import actionManager from  '../../actions';

import Icon from  '../common/Icon.vue';

export default {

    name: "Tagging",
	props: ['library', 'tagModal'],
    
	components: { Icon },

	data() {
		return {
			newTag: '',
			tags: [],
			medias: [],
            error: ''
		}
	},

	created: function(){
		let self = this
		this.medias = []

        if (this.tagModal) {
            this.library.mediaList.forEach( (media) => {
                self.library.mediasUploaded.forEach( (mediaUploaded) => {
                    if (media._id == mediaUploaded._id) this.medias.push(Object.assign({}, media))
                })
            })
        } else {
            this.medias = this.library.mediaPreview
            this.tags = this.library.mediaPreview.tags
        }
		
	},

	computed: {
		canEditJSON() {
			const isJSON = (
				this.library.mediaPreview &&
				this.library.mediaPreview.mimeType === 'application/json'
			);

			return (isJSON && !this.tagModal);
		},
	},

	methods:{

		addTag: function(){ 
            if (this.newTag.includes(" ")) {
				this.error = "Space are not allow in tag name."
					setTimeout( () => {
                        this.error = ""
                    }, 3000)
				}
			else if (this.tags.indexOf(this.newTag) == -1) {
                // update tag on every media document.

                if (this.tagModal){ // if tagModal add to the list (need confirm)
                    this.tags.push(this.newTag)
                    this.medias.forEach( (media) => {
                        if (media.tags) media.tags.push(this.newTag)
                        else media.tags = [this.newTag]
                    })
                } else { //simply post the new tag
                    actionManager.trigger('library:tag', { tag: this.newTag });
                    this.newTag = ""
                }
                
				
				this.error = ""
			} else this.error = ""

			this.newTag = ""
		},

		deleteTag: function(i){
            if (!this.tagModal) actionManager.trigger('library:tag', { subaction: "remove", tagIndex: i });
            else this.tags.splice(i, 1)
		},

		confirmTags: function(){
			// Trigger update of the documents with the new tags.
            let sendTag = {
				tags: this.tags,
				medias: this.medias
			}
			actionManager.trigger('library:tag', {tagMedias: sendTag});
            // if from ToggleTagModal
            if (this.tagModal){
                actionManager.trigger('main:ToggleTagModal', {open: false})
                this.library.mediasUploaded = []
            }
		},

        chooseFile: function(){
			actionManager.trigger('library:selectAssetForField', { mediaId: this.library.mediaPreview._id, target: this.library.target });
		},

        uploadFile: function( event ){
			var files = event.target.files || event.dataTransfer.files;
            if (!files.length) return;
			actionManager.trigger('library:addMedia', { file: files[0], target: this.library.mediaPreview });
		},
        copyURL: function(s){
			let copiedText = document.getElementById('urlToCopy');
			copiedText.select();
			document.execCommand("copy");

			/*var copyText = document.getElementById("myInput");
			copyText.select();
			document.execCommand("copy");
			alert("Copied the text: " + copyText.value);*/
		},
		editJSON: async function() {
			const response = await fetch(this.library.mediaPreview.url);
			const data = await response.text();

			const replaceFile = (updatedData) => {
				const file = new File([updatedData], this.library.mediaPreview.filename, {
					type: 'application/json',
				});

				actionManager.trigger('library:addMedia', { file, target: this.library.mediaPreview });
			};

			actionManager.trigger('main:toggleJson', {
				open: true,
				from: 'LIBRARY',
				content: data,
				callback: () => replaceFile,
			});
		},
		editFilename: function() {
			document.getElementById('editname').style.display = 'none';
			document.getElementById('filename').disabled = false;
		},
		saveFilename: function(id) {
			const filename = document.getElementById('filename').value;
			actionManager.trigger('library:renameMedia', {id, filename});
			document.getElementById('editname').style.display = 'block';
			document.getElementById('filename').disabled = true;
		}
    }
}
</script>


<style>
.confirmTags{
	    /* background: var(--saveColor); */
    transition: linear all 0.1s !important;
    border-radius: 40px;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin: auto;
    font-size: 14px;
    font-family: 'DinBold', 'ComfortaaLight';
    line-height: 40px;
    text-align: center;
    background-color: #707070;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
	overflow-y: scroll;
	
}
.confirmTags > p {
	margin: 0;
}
.taglistMedias{
	width: 100%;
	color: var(--inputTextColor);
}
.taglistMedias > .media {
	font-family: 'DinBold';
    font-size: 14px;
    word-wrap: break-word;
    color: var(--inputTextColor);
    background: var(--inspectorTargetColor);
    border-radius: 4px;
    padding: 10px;
    margin: 5px;
    text-align: center;
}
.title > h1 {
	text-align: center;
    color: var(--inputTextColor);
    font-family: 'DinBold';
    font-size: 28px;
    border-bottom: 1px solid var(--inputTextColor);
	padding-top: 8px;
    padding-bottom: 8px;
    margin: 8px;
}
.mess > h3 {
	text-align: center;
    color: var(--inputTextColor);
    font-family: 'DinRegular', 'ComfortaaLight';
    padding-bottom: 8px;
    margin: 8px;
}
.clickableMask {
    cursor: pointer;
}

</style>

<style lang="stylus" scoped>
		.tagging
			.meta
				display: block
				width: 269px
				height: 100%
				float:left
				background-color: rgba(247,185,149,0.3)
				font-size: 14px
				position: absolute
				top: 0
				right: 0
				border-radius: 0 16px 16px 0

				.dataTag
					margin: 10px
					word-wrap: break-word
					position: relative

					.infoTopic
						background: var(--inspectorLevel1)
						border-radius: 8px
						padding-bottom: 0.1px

					.title
						font-family: 'DinBold'
						font-size: 14px
						word-wrap: break-word
						color: var(--inputTextColor)
						background: var(--inspectorTargetColor)
						border-radius: 4px
						padding: 10px
						margin: 5px

					.subtitle
						margin-top: 10px
						background: var(--inputValue)
						line-height: 40px
						height: 40px
						color: var(--descriptionBgColor)
						border-radius: 8px 8px 0px 0px
						padding-left: 10px
						margin-bottom: 5px
						font-family: 'DinBold'
						font-size: 16px

					.addTag
						margin: 5px
						vertical-align: top
						height: 40px

						input[type="text"]
							height: 40px
							border: 0px
							margin: 0
							padding: 0
							border-radius: 4px 0px 0px 4px
							padding-left: 10px
							line-height: 20px
							font-size: 14px
							font-family: 'DinBold'
							color: var(--inputTextColor)
							background: var(--bgColor)
							display: inline-block
							width: 170px
							vertical-align: top
							&:focus
								outline: none

						input[type="submit"]
							height: 40px
							line-height: 40px
							display: inline-block
							width: 59px
							color: white
							background-color: var(--saveColor)
							border-radius: 0px 4px 4px 0px
							border: none
							font-family: 'DinBold'
							font-size: 14px
							vertical-align: top
							text-align: center
							transition: linear all .1s !important;
							&:hover
								background: var(--selectedSaveColor)
							&:focus
								outline: none

					.listTags
						p
							text-align: center
							font-family: 'DINNextLTPro-Regular'
						ul
							font-family: 'DinRegular', 'ComfortaaLight'
							font-size: 14px
							line-height: 40px
							border-radius: 8px
							list-style-type: none
							padding: 5px
							margin: 0
							margin-top: -5px
							padding-bottom: .1px

							li
								padding: 0
								margin: 0
								margin-bottom: 5px
								padding-left: 30px
								position: relative
								color: var(--inputTextColor);
								background: var(--inspectorTargetColor)
								border-radius: 4px
								font-family: 'DinBold'
								transition: linear all .1s !important
								&:hover
									background: var(--inspectorTargetOverColor)

								.bt-delete
									position: absolute
									top: 50%
									left: 10px
									.icon
										position: absolute
										top: 0
										left: 0
										width: 15px
										height: 15px
										background-color: var(--inputTextColor)

				div.bt-choose
					display: inline-block
					background-color: #F39A74
					height: 40px
					line-height: 40px
					font-family: 'DinBold'
					font-size: 16px
					color : #ffffff
					text-align: center
					position: absolute
					bottom: 10px
					left: 10px
					right: 10px
					border-radius: 8px
					opacity: 1

				div.bt-jsonedit
					position: static
					height: 40px
					line-height: 40px
					color: white
					text-align: center
					font-family: 'DinBold'
					background-color: var(--saveColor)
					border-radius: 40px
					transition: linear all .1s
					&:hover
						cursor: pointer
						background-color: var(--selectedSaveColor)

	div.bt-close
		display: block
		width: 26px
		height: 26px
		background-color: transparent
		color: white
		position: absolute
		top: -34px
		right: 4px
		text-align: center
		border: 1px solid white
		border-radius: 26px
		z-index: 10
		transform: rotate(45deg)
		cursor: pointer
		visibility: hidden

		&:before
			content:''
			display: block
			width: 50%
			height: 1px
			background-color: white
			position: absolute
			margin-top: -0.5px
			top: 50%
			left: 25%

		&:after
			content:''
			display: block
			height: 50%
			width: 1px
			background-color: white
			position: absolute
			left: 50%
			margin-left: -0.5px
			top: 25%

input#filename
input#urlToCopy
	border: 0
	margin: 5px
	background: var(--inspectorTargetColor)
	font-family: 'DinBold'
	color: var(--inputTextColor)
	font-size: 14px
	padding: 10px
	border-radius: 4px
	margin-top: 0px
	width: calc(100% - 30px)
	&:focus
		outline: none!important

.clickableField
	position: relative

.clickableMask
	position: absolute
	top: 0
	left: 5px
	height: calc(100% - 5px)
	width: calc(100% - 10px)
	border-radius: 4px
	background: var(--selectedColor)
	opacity: 0
	transition: linear all 0.2s !important
	text-align: center
	line-height: 40px
	font-family: 'DinBold'
	color: white

.clickableMask:hover
	opacity: 1

.vc-chrome-color-wrap .vc-checkerboard
	width: 28px !important
	height: 28px !important

.vc-chrome-active-color
	width: 26px !important
	height: 26px !important
	border-radius: 35px !important
	border: 1px solid var(--inputTextColor) !important

.vc-chrome-body
	background: var(--bgColor) !important

.vc-chrome-hue-wrap .vc-hue
	border-radius: 35px !important

.vc-alpha-checkboard-wrap
	border-radius: 35px !important

.vc-chrome-fields-wrap
	padding-top: 0px !important

.vc-chrome-toggle-btn
	width: 15px !important

.vc-input__input
	line-height: 29px !important
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	box-shadow: none !important
	background-color: var(--menuElementColor) !important
	border-radius: 40px  !important

.vc-chrome-toggle-icon
	margin-top: 8px !important

.vc-chrome-toggle-icon-highlight
	background: none !important

.vc-chrome-fields .vc-input__label
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	margin-top: 0px !important
</style>