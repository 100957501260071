<!-- Topic Inspector Bloc vue -->

<template lang="pug">
div.accordion.topic(v-bind:class="[{'invisible-setting': bloc.invisible}, {'hide': isObj && bloc.hideForObjInspector === true}]")
	div.accordion-title(v-on:click.stop="accordion = !accordion")
		span.text {{bloc.title}}
		span.arrow(v-if="bloc.isAccordion", v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}", v-if="bloc.children && bloc.children.length > 0")
		div.children(v-for="subbloc of bloc.children")
			group(v-if="(subbloc.type == 'group' || subbloc.type == 'param') && hasChild(subbloc) && conditionIsFilled(subbloc)", :project="project", :target="target", :bloc="subbloc", :state="state", :inspectorField="inspectorField")

</template>

<script>

import Group from  './Group.vue';

export default {

	props:[ 'project', 'target', 'bloc', 'state', 'inspectorField' ],

	components : { Group },

	data() {
		return {
			accordion: true,
			hasNoDisplayedChild: false,
			inspectorOptions: null,
		}
	},
	computed : {
		isObj : function(){
			if( this.target && this.target.value.type == "object" )
				return true;
			return false;
		},
		unDisplayedField: function(){
			const unDisplayedfields = [];
			if(this.target && this.target.custom.fields){
				Object.keys(this.target.custom.fields).forEach((field) => {
						if(!this.target.custom.fields[field].display){
							unDisplayedfields.push(this.target.custom.fields[field].name)
						}
				});
				let count = 0
				const topicFields = []
				let hiddingFields = {};
				if ( this.bloc.children ) this.bloc.children.forEach((child) => {
					if(child.children){
						let grandChild = child.children;
						if ( grandChild ) grandChild.forEach((child2) => {
							let ggrandChild = child2.children
							let array = [];

							if ( ggrandChild ) ggrandChild.forEach((child3) => {
								if(unDisplayedfields.includes(child3.name)){
									array.push(child3.name)
									hiddingFields[child.name] = array
								} else {
									return hiddingFields
								}
							});
						});
					};
				});
				return hiddingFields
			}
		},
	},

	methods: {

			selectHasChanged: function(newVal){
				this.inspectorOptions = newVal;
			},

			hasChild: function(subbloc) {
				if(subbloc.children && subbloc.children.length > 0) {
					let totalSubBlocField = [];
					subbloc.children.forEach((subgroup) => {
						if(subgroup.children){
							subgroup.children.forEach(field => {
								totalSubBlocField.push(field.name)
							})
						}
					})

						if(this.unDisplayedField && Object.keys(this.unDisplayedField).includes(subbloc.name) && totalSubBlocField.length === this.unDisplayedField[subbloc.name].length){
							return false
						} else {
							return true
						}
				} else {
					return true
				}
			},

			conditionIsFilled: function(subbloc){
				let hasAtLeastOneConditionTrue = 0;
				let array = []
				if(subbloc.conditions){
					subbloc.conditions.forEach((condition) => {
						let fieldReference = condition.field;
						let operator = condition.operator;
						let value1 = condition.value;
						let type = this.inspectorOptions;

						this.target.value.fields.forEach((field) => {
							if(field.name === fieldReference){
								let value2 = field.value;
								if( value1 !== null && value2 !== null){
									switch( operator ){
										case '==':
											if( value1 != value2 ) array.push('false')
											else array.push('true')
											break;

										case '!=':
											if( value1 == value2 ) array.push('false')
											else array.push('true')
											break;

										case '>':
											if( value1 <= value2 ) array.push('false')
											else array.push('true')
											break;

										case '<':
											if( value1 >= value2 ) array.push('false')
											else array.push('true')
											break;

										case '>=':
											if( value1 < value2 ) array.push('false')
											else array.push('true')
											break;

										case '<=':
											if( value1 > value2 ) array.push('false')
											else array.push('true')
											break;
									}
								}
							}
						})

					})
					 if(!array.includes('false')){
						return true
					}
				} else {
					return true
				}
			}
	},
}

</script>

<style lang="stylus">


</style>
