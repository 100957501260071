var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeModal" } }, [
    _c(
      "div",
      { attrs: { id: "modalWindow" } },
      [
        _vm.waiting ? _c("spinner") : _vm._e(),
        _c("div", {
          staticClass: "cover-back",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.cancelModal($event)
            }
          }
        }),
        _c("div", { staticClass: "modal" }, [
          _c("div", { staticClass: "section" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("icon", { attrs: { name: _vm.settings.icon } })],
                1
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.settings.title))
              ]),
              _vm.settings.isUsedBy && _vm.settings.isUsedBy.length > 0
                ? _c("div", { staticClass: "warning" }, [
                    _c("p", { staticClass: "warning-text" }, [
                      _vm._v(
                        "The " +
                          _vm._s(
                            _vm.settings.blocIsMemory ? "memory" : "UI element"
                          ) +
                          " is used in:"
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "warning-list" },
                      _vm._l(_vm.settings.isUsedBy, function(bloc) {
                        return _c("p", { staticClass: "warning-text" }, [
                          _vm._v(_vm._s(bloc))
                        ])
                      }),
                      0
                    ),
                    _c("p", { staticClass: "warning-text" }, [
                      _vm._v(
                        "Please remove all references to this " +
                          _vm._s(
                            _vm.settings.blocIsMemory ? "memory" : "UI element"
                          ) +
                          " in the project before deleting it. This will cause the schematic to break and unsaved modifications will be lost."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.settings.type &&
              _vm.settings.type.toLowerCase() !== "mixed" &&
              _vm.settings.type.toLowerCase() !== "calculated"
                ? _c("div", { staticClass: "type" }, [
                    _c(
                      "div",
                      { staticClass: "containerType" },
                      [
                        _c("icon", {
                          attrs: { name: _vm.settings.type.toLowerCase() }
                        }),
                        _c("p", [_vm._v(_vm._s(_vm.settings.type))])
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("p", { staticClass: "message" }),
              _c("p", [_vm._v(_vm._s(_vm.settings.text))]),
              _vm.settings.isBuildOnly
                ? _c("p", { staticClass: "buildOnlyDoc" }, [
                    _vm._v(
                      "This field is only available for built applications."
                    )
                  ])
                : _vm._e(),
              _c("p"),
              _vm.settings.hasInput
                ? _c("div", { staticClass: "input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputVal,
                          expression: "inputVal"
                        }
                      ],
                      staticClass: "value inputModal",
                      attrs: { type: "text" },
                      domProps: { value: _vm.inputVal },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputVal = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm.settings.share
                ? _c(
                    "div",
                    { staticClass: "shareWraper" },
                    [
                      _c("sharing", {
                        attrs: {
                          projectInfo: _vm.selectedProject,
                          index: _vm.index,
                          sharingError: _vm.settings.sharingError
                        },
                        on: { "send-index": _vm.handleIndexEvent }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings.duplicate
                ? _c(
                    "div",
                    { staticClass: "duplicateWraper" },
                    [
                      _c("duplicate", {
                        attrs: {
                          projectInfo: _vm.selectedProject,
                          index: _vm.index,
                          duplicateError: _vm.settings.duplicateError
                        },
                        on: { "send-index": _vm.handleIndexEvent }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings.download
                ? _c(
                    "div",
                    { staticClass: "downloadWraper" },
                    [
                      _c("downloadProject", {
                        attrs: { download: _vm.settings.download }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          !_vm.settings.share ||
          !_vm.settings.duplicate ||
          !_vm.settings.download
            ? _c("div", { staticClass: "section" }, [
                _c("div", { staticClass: "action" }, [
                  _vm.settings.cancelButton != "" && _vm.settings.cancelButton
                    ? _c(
                        "div",
                        {
                          staticClass: "button cancel",
                          on: { click: _vm.cancelModal }
                        },
                        [_c("p", [_vm._v(_vm._s(_vm.settings.cancelButton))])]
                      )
                    : _vm._e(),
                  _vm.settings.continueButton
                    ? _c(
                        "div",
                        {
                          staticClass: "button confirm",
                          class: [
                            {
                              onebutton: _vm.settings.continueButton == "",
                              isUsedBy:
                                _vm.settings.isUsedBy &&
                                _vm.settings.isUsedBy.length > 0
                            }
                          ],
                          on: { click: _vm.confirmModal }
                        },
                        [_c("p", [_vm._v(_vm._s(_vm.settings.continueButton))])]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }