import ActionAbstract from '../ActionAbstract';
import deepCopy from 'deepcopy';

class AddMedia extends ActionAbstract {

    static get ID() {
        return 'actions:library:addMedia';
    }

    run(state) {
        let mediaList = []
        let files = this.getParam('file');

        const addedFrom =  state.workspace.id;
        const templateS = deepCopy(state.project.templates);
        const allPosts = []
        let self = this

        if (files.name) files = [this.getParam('file')]

        files.forEach( (file) => {
            let data = new FormData();
            data.append('file', file);

            if (typeof file !== 'function') {
                let postReqFile = this.deps.mainStore.lib.postFile( file.name, data, this.getParam('target'), addedFrom)
                allPosts.push(postReqFile)
            }

        })

        const postAllMedias = async () => {
            await Promise.all(allPosts)
            .then( (medias) => {

                medias.forEach((media) => {
                    if (!media.value) mediaList.push(media)
                    else {
                        mediaList.push(media.value)
                        if(addedFrom !== state.project.value._id){
                            templateS.forEach((temp) => {
                                if(temp.value._id === addedFrom) temp.media.push(media.value._id)
                            })
                        }
                    }
                })

            });
        }

        (async function() {
            try {

                let uploadedMedias = []

                await postAllMedias().catch( (errors) => {
                    //this.trigger('notification', { message: this.getParam('message'), type: 'error' });
                    console.error('notification', { message: errors, type: 'error' });
                })
                self.trigger('library:fetchAssets');

                if (self.getParam('target')) { // Update from mediaPreview (one file replace)
                    alert('Your media has been successfully updated');
                    self.trigger('library:mediaPreview', { media: mediaList[0] });
                } else {
                    mediaList.forEach( (media) => {
                        uploadedMedias.push(Object.assign({}, media))
                    })

                    self.trigger('main:ToggleTagModal', {
                        open: true,
                        mediasUploaded: uploadedMedias
                    })
                }
                self.trigger('library:fetchAssets');
            } catch(err) { 
                console.error('[postAllMedias] error', err);
            }
        })()

        return this.composeState(state, {
            library: {
                waiting: true
            },
            project: {
                templates: templateS
            }
        });

    }

}

export default AddMedia;            