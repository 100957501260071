var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-accordion",
      class: [
        { group: _vm.bloc.isAccordion },
        { isAccordion: _vm.bloc.isAccordion },
        { "invisible-setting": _vm.bloc.invisible },
        { hide: _vm.isObj && _vm.bloc.hideForObjInspector === true }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.accordion = !_vm.accordion
            }
          }
        },
        [
          _vm.bloc.title
            ? _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.bloc.title))
              ])
            : _vm._e(),
          _vm.bloc.isAccordion
            ? _c("span", {
                staticClass: "arrow",
                class: { close: !_vm.accordion }
              })
            : _vm._e(),
          _vm.bloc.type == "group" && _vm.bloc.isAccordion
            ? _c("div", { staticClass: "legend" }, [
                _c("span", { staticClass: "legend-default" }, [
                  _vm._v("default")
                ]),
                _c("span", { staticClass: "legend-in" }, [_vm._v("in")]),
                _c("span", { staticClass: "legend-out" }, [_vm._v("out")])
              ])
            : _vm._e(),
          _vm.bloc.type == "param" && _vm.bloc.hideForObjInspector !== true
            ? _c("field", {
                attrs: { field: _vm.bloc, bloc: _vm.target, state: _vm.state }
              })
            : _vm._e()
        ],
        1
      ),
      (_vm.subforms && _vm.subforms.length > 0) || _vm.bloc.isRepeatedForm
        ? _c(
            "div",
            {
              key: _vm.currentNBRepeatedField,
              staticClass: "form-accordion-content",
              class: { open: _vm.accordion }
            },
            [
              _vm._l(_vm.subforms, function(subbloc, index) {
                return _c(
                  "div",
                  { staticClass: "children" },
                  [
                    _vm.bloc.isRepeatedForm
                      ? _c("div", { staticClass: "chapo-repeatedform" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.bloc.label) + " #" + _vm._s(index + 1)
                            )
                          ]),
                          _c("div", { staticClass: "action-bts" }, [
                            _c("div", {
                              staticClass: "action-bt bt-trash",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteRepeatedForm(subbloc)
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _c("group-field", {
                      class: {
                        "repeated-group-field": _vm.bloc.isRepeatedForm
                      },
                      attrs: {
                        project: _vm.project,
                        target: _vm.target,
                        bloc: subbloc,
                        state: _vm.state,
                        inspectorField: _vm.inspectorField
                      }
                    })
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "button-repeated-form" }, [
                _vm.bloc.isRepeatedForm &&
                this.repeatedForm &&
                this.repeatedForm.description
                  ? _c(
                      "span",
                      {
                        staticClass: "description-bull-repeated-form",
                        on: { click: _vm.displayDescription }
                      },
                      [_vm._v("?")]
                    )
                  : _vm._e(),
                _vm.bloc.isRepeatedForm &&
                _vm.nbRepeatedField === null &&
                this.repeatedForm
                  ? _c(
                      "div",
                      {
                        staticClass: "big-bt bt-repeat-form",
                        on: { click: _vm.addRepeatedForm }
                      },
                      [
                        _c("span", { staticClass: "text" }, [
                          _vm._v("Add " + _vm._s(this.repeatedForm.label))
                        ]),
                        _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
                      ]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }