/*
	Main Store instance -
	instanciate all stores and is used as a passerel to them	
*/
import StoreAbstract from './StoreAbstract';

import ServiceManager from '../services/ServiceManager';
import serviceManager from '../services';

import { user } from './UserStore.js';
import { projects } from './ProjectsStore.js';
import { project } from './ProjectStore.js';
import { block } from './BlockStore.js';
import { memory } from './MemoryStore.js';
import { objects } from './ObjectsStore.js';
import { ui } from './UIObjectStore';
import { lib } from './LibraryStore.js';
import { template } from './TemplateStore.js';
import { authoring } from './AuthoringStore.js';

import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

class MainStore extends StoreAbstract {

    static get ID() {
        return 'stores:MainStore';
    }

	config() {
		return{
			
			bdd : 					"https://" + env.domain_db + "/",
			baseURL : 				"https://" + env.domain_db + "/cosima/",
			baseURLAuth : 			"https://" + env.domain_db + "/authoring/",

			assetURL : 				"//" + env.domain_asset + "/",
			previewURL : 			"//" + env.domain_app + "/",

			pouchOpts : 			{ skipSetup : true },
			$route : 				null,
			user : 					null,
			metaUser : 				null,
			SESSION_DURATION : 		1,
			sessionTimer: 			null,

			projects : 				null,
			openProject : 			null,
			authoring : 			null,
			datatypes : 			null,

			editorView :  			[ 'project', 'experience', 'screen', 'process', 'object', 'widget' ],
			editorHtmlViewLayout:   ['screen', 'box', 'canvas', 'layout-2-col', 'map', 'map-layer'],

			check : 				env.admin_pass || 'orbe-admin'
		}
	}

	getInitialState() {
		this.state = super.getInitialState() || {};
        return this.state;
    }

	beforeCreate(){

		super.beforeCreate();

		user.setMain( this );
		this.user = this.wrapStore(user);

		lib.setMain( this );
		this.lib = this.wrapStore(lib);

		template.setMain(this);
		this.template = this.wrapStore(template);

		block.setMain( this );
		this.block = this.wrapStore(block);

		memory.setMain( this );
		this.memory = this.wrapStore(memory);

		objects.setMain( this );
		this.objects = this.wrapStore(objects);

		ui.setMain( this );
		this.ui = this.wrapStore(ui);

		authoring.setMain( this );
		this.authoring = this.wrapStore(authoring);

		// Inits
		this.config = this.config();
		this.authoring.getDefaultRefs(true);
		this.authoring.getAuthoringConfig(true);

		projects.setMain( this );
		this.projects = this.wrapStore(projects);

		project.setMain( this );
		this.project = this.wrapStore(project);

		this.user.beforeCreate();
	}

	resetConfig(){
		this.config.session = false;
		this.config.user = null;
		this.config.metaUser = null;
		this.config.projects = null;
		this.config.openProject = null;
	}

	/**
	 * wrap stores into proxy to gather information about store usage
	 * the aim is to detect dead code
	 */
	wrapStore(store) {
		// @todo
		return store;
	}

	// UserStore Access
	user(){ return this.user; }

	// LibraryStore Access
	lib(){ return this.lib; }

	// TemplateStore Access
	template() { return this.template; }

	// ProjectsStore Access
	projects(){ return this.projects; }

	// ProjectStore Access
	project(){ return this.project; }

	// BlockStore Access
	block(){ return this.block; }

	// MemoryStore Access
	memory(){ return this.memory; }

	// ObjectsStore Access
	objects(){ return this.objects; }

	// UIObjectStore Access
	ui(){ return this.ui; }

	// Authoring Access
	authoring(){ return this.authoring; }

	setProjectURL( idProject ){
		this.config.baseURL = this.config.bdd+'nodal-project-'+idProject+'/';
	}
}

ServiceManager.register(MainStore);

export let stores = serviceManager.get('stores:MainStore')
