<!-- String vue -->

<template lang="pug">

span.textinput-ctn
	input.value(type="text", v-model="value", v-bind:placeholder="placeholder", v-on:focusout="valueUpdated(field)", :disabled="field.disabled")
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : "";
	},

	watch:{
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : "";
		}
	},

	methods:{

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value );
		}


	}

}

</script>

<style lang="stylus">
</style>