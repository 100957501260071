var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspector-panel", class: _vm.target.value.type },
    [
      _c(
        "div",
        { staticClass: "inspector-content" },
        [
          _c("div", { staticClass: "main-title" }, [
            _c("div", { staticClass: "type" }, [
              _vm._v(_vm._s(_vm.blockTypeName))
            ]),
            _c("div", { staticClass: "help" }, [
              _c("span", { staticClass: "icon" }, [_vm._v("?")]),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.blockDescription))
              ])
            ]),
            _c("input", {
              staticClass: "invisible",
              domProps: { value: _vm.name },
              on: { keyup: _vm.updateName }
            }),
            _c("div", { staticClass: "url" }, [
              _c("span", [_vm._v("URL :")]),
              _c("input", {
                staticClass: "invisible",
                domProps: { value: _vm.slug },
                on: { change: _vm.updateSlug }
              }),
              _c("div", { staticClass: "clear" })
            ])
          ]),
          _vm.inspectorField && _vm.target
            ? _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.hasWidgetManager()
                    ? _c("widget-visibility-manager", {
                        attrs: { project: _vm.project, target: _vm.target }
                      })
                    : _vm._e(),
                  _vm._l(_vm.inspectorField.children, function(bloc) {
                    return _c(
                      "div",
                      { staticClass: "children" },
                      [
                        bloc.type == "topic"
                          ? _c("topic", {
                              attrs: {
                                project: _vm.project,
                                target: _vm.target,
                                bloc: bloc,
                                state: _vm.state
                              }
                            })
                          : _c("widget", {
                              attrs: {
                                project: _vm.project,
                                target: _vm.target,
                                bloc: bloc,
                                schematicLevel: _vm.inspector.schematic
                                  ? _vm.inspector.schematic.level
                                  : "",
                                state: _vm.state,
                                inspectorField: _vm.inspectorField
                              }
                            })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.target.value.type == "experience"
                ? _c("targets", {
                    attrs: {
                      target: _vm.target,
                      "available-targets": _vm.confTarget
                    }
                  })
                : _vm._e(),
              _vm.target.value.type == "experience"
                ? _c("screens", { attrs: { target: _vm.target } })
                : _vm._e(),
              _vm.target.value.type == "process"
                ? _c("auto-start-selector", { attrs: { target: _vm.target } })
                : _vm._e(),
              _vm.hasMemory(_vm.target.value.type)
                ? _c("memory", {
                    attrs: {
                      project: _vm.project,
                      appConfig: _vm.appConfig,
                      target: _vm.target,
                      "inspector-conf": _vm.confMemory
                    }
                  })
                : _vm._e(),
              _vm.target.value.type == "project"
                ? _c("sessions", {
                    attrs: { project: _vm.project, target: _vm.target }
                  })
                : _vm._e(),
              _vm.target.value.type == "trigger"
                ? _c("trigger", {
                    attrs: { target: _vm.target, project: _vm.project }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._l(_vm.objectsToConfigure, function(objInspector) {
            return _c(
              "div",
              { staticClass: "content" },
              _vm._l(objInspector.inspector.children, function(bloc) {
                return _c(
                  "div",
                  { staticClass: "children" },
                  [
                    bloc.type == "topic"
                      ? _c("topic", {
                          attrs: {
                            project: _vm.project,
                            target: objInspector.target,
                            bloc: bloc,
                            values: objInspector.values
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          }),
          _c("div", { staticClass: "accordion save-zone" }, [
            _c("div", { staticClass: "form-content open" }, [
              _c(
                "div",
                {
                  staticClass: "save-bt",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.save()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Save " + _vm._s(_vm.target.value.level))
                  ])
                ]
              )
            ])
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }