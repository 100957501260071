<!-- Select vue -->

<template lang="pug">

span.textinput-ctn
	span.select-arrow &gt;
	select(v-on:change="valueUpdated(field)", v-model="value", :disabled="field.disabled")
		option(v-for="(option,i) of options", v-bind:value="option.value") {{option.lbl}}

</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	computed : {

		'options': function(){
			return this.field.options.map( (option) => {
				option.lbl = option.label;
				if (option.deprecated) option.lbl += " (deprecated)";
				if( option.value == this.placeholder )
					option.lbl += ' (default)';
				return option;
			});
		}

	},
	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : null;

		if( !this.value && this.placeholder )
			this.value = this.placeholder;
	},

	destroyed: function(){

	},

	watch:{
		'value': function(newVal, oldVal){
			this.selectHasChanged(newVal)
		}
	},

	methods:{
		selectHasChanged: function(newVal){
			this.$parent.selectHasChanged(newVal);
		},

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value);
		}


	}

}

</script>

<style lang="stylus">
</style>
