<!-- Modal vue -->

<template lang="pug">
<transition name="fadeModal">
div#modalWindow
    spinner(v-if="waiting")
    div.cover-back(v-on:click.stop="cancelModal")
    div.modal
        div.section
            div.content
                div.icon
                    icon(:name="settings.icon")
                h3.title {{ settings.title }}
                div.warning(v-if="settings.isUsedBy && settings.isUsedBy.length > 0")
                    p.warning-text The {{ settings.blocIsMemory ? 'memory' : 'UI element'}} is used in:
                    div.warning-list
                        p.warning-text(v-for="(bloc) of settings.isUsedBy") {{bloc}}
                    p.warning-text Please remove all references to this {{ settings.blocIsMemory ? 'memory' : 'UI element'}} in the project before deleting it. This will cause the schematic to break and unsaved modifications will be lost.
                div.type(v-if="settings.type && settings.type.toLowerCase() !== 'mixed' && settings.type.toLowerCase() !== 'calculated'")
                    div.containerType
                        icon(:name="settings.type.toLowerCase()")
                        p {{settings.type}}
                p.message
                    p {{ settings.text }}
                    p.buildOnlyDoc(v-if="settings.isBuildOnly") This field is only available for built applications.
                div.input(v-if="settings.hasInput")
                    input.value.inputModal(type="text", v-model="inputVal" )

                div.shareWraper(v-if="settings.share")
                    sharing(:projectInfo="selectedProject", :index="index", :sharingError='settings.sharingError', @send-index="handleIndexEvent")
    
                div.duplicateWraper(v-if="settings.duplicate" )
                    duplicate(:projectInfo="selectedProject", :index="index", :duplicateError='settings.duplicateError', @send-index="handleIndexEvent")

                div.downloadWraper(v-if="settings.download" )
                    downloadProject(:download="settings.download")

        div.section(v-if="!settings.share || !settings.duplicate || !settings.download" )
            div.action
                div.button.cancel(v-if="settings.cancelButton != '' && settings.cancelButton", v-on:click="cancelModal")
                    p {{settings.cancelButton}}
                div.button.confirm(v-if="settings.continueButton", v-bind:class="[{'onebutton' : settings.continueButton == '', 'isUsedBy': settings.isUsedBy && settings.isUsedBy.length > 0 }]", v-on:click="confirmModal")
                    p {{settings.continueButton}}
</transition>
</template>


<script>
import Icon from './Icon.vue'
import actionManager from '../../actions';
import downloadProject from './downloadProject.vue';
import sharing from '../projects/sharing.vue';
import duplicate from '../projects/duplicate.vue';
import Spinner from  '../common/Spinner.vue';


export default {

    // settings	the modal configuration (ie. app.state.modal)
	props: {
		settings: { type: Object},
		project: { type: Object},
		index: {
        type: Number,
        default: 0 
    },
		method: { type: Function },
		selectedProject: { type: Object}
	},
    components: {
        Icon, sharing, Spinner, duplicate, downloadProject
    },

		data() {
        return {
            inputVal: null,
            waiting: false,
        }
    },

    created: function() {

    },

    methods: {
		handleIndexEvent(event) { // send index from project to sharing modal
            if (this.duplicateProject) this.duplicateProject.index = event
            if (this.shareProject) this.shareProject.index = event
			this.waiting = true
        },

        cancelModal: function() {
            this.duplicateError = ''
            actionManager.trigger('main:modal:closeModal');
        },

        confirmModal: function() {

            let data = {};
            if (this.settings.hasInput === true && this.settings.inputName != undefined && (this.inputVal !== undefined && this.inputVal !== null)) {
                data[this.settings.inputName] = this.inputVal;
            }

            actionManager.trigger('main:modal:confirmModal', data);
        },

    }

}
</script>

<style lang="stylus" scoped>
/* ModalWindow */

// TODO: place in global styles
.fade-enter
.fade-leave-to
    opacity: 0

.fade-enter-active
.fade-leave-active
    transition: opacity .5s

.cover-back
	height: 100%
	width: 100vw
	background-color: var(--modalTransparentBgColor)

#modalWindow
    position: fixed
    z-index: 100
    width: 100%
    height: 100%
    top: 0
    left: 0
    color: var(--inputTextColor)

    .modal
        position: absolute
        background-color: var(--bgColor)
        width: 50%
        height: 50%
        padding: 40px
        top: calc(25% - 20px)
        left: calc(25% - 20px)
        border-radius: 10px
        box-shadow: 0 0 10px var(--shadowColor)

        // mutualized styles ----------------------------------
        display: flex
        flex-flow: column nowrap

        .section:first-child
                flex-grow: 1
                align-items: center
                display: flex
                flex-flow: column nowrap
                justify-content: center
        .content
        .action
            width: 100%
            text-align: center

        .content
            .icon
                width: 128px
                height: 128px
                border-radius: 50%
                transform: translateY(0%)
                display: inline-block
                background: var(--modalIconColor)
                flex-grow: 1
                color: var(--inputTextColor)

                span
                    display: inline-block
                    background-color: #707070
            .warning
                color: #F77963
                font-size: bold
            .type
                .containerType
                    width: fit-content
                    margin: 0 auto
                    display: flex
                    align-items: center
                    justify-content: space-between
                    .icon
                        width: 30px
                        height: 30px
                        transform: translateY(0%)
                        display: inline-block
                        background: var(--modalTransparentBgColor)
                        flex-grow: 1
                        color: var(--inputTextColor)
                    p
                        margin: 1px 1px 1px 6px

            .buildOnlyDoc
                color: #f77963
                font-family: 'DinBold'

            .title
                text-align: center
                color: var(--inputTextColor)
                font-family: 'DinBold'
                font-size: 28px // TODO: MUST BE DEFINE IN GLOBALS
                border-bottom: 1px solid var(--inputTextColor)
                padding-bottom: 8px
                margin: 8px 0

            .warning
                .warning-text
                    margin: 0                
                .warning-list
                    padding: 7px 0 7px 0
			.input
                color: black
                background-color: #e6e8e7
                height: 20px
                border-radius: 8px
                border: none
                width: calc(100% - 50px)
                padding: 4px 25px
                line-height: 24px
                text-align: center
                font-size: 14px
                margin-top: 10px
                .value
                    font-size: 14px
                    font-family: 'DinRegular'

                .inputModal
                    width: 100%
                    background-color: #e6e8e7
                    border: none
                    text-align: center;
                    height: 18px

            .action
                .button
                    font-family: 'DinBold'
                    display: inline-block
                    text-align: center
                    color: white
                    background-color:var(--selectedColor)
                    width: 160px
                    height: 20px
                    padding: 8px 10px
                    border: none
                    border-radius: 40px
                    cursor: pointer
                    font-size: 16px
                    margin: 4px
                    opacity: 0.5
                    transition: linear all .1s !important

                    &.nofocus
                        opacity: 0.3
                    &.button:hover
                        opacity: 1

                    &:disabled
	                    opacity: 0.3
	                    cursor: inherit
                    p
	                    margin: 0
	                    padding: 0
                    &.isUsedBy
                        opacity: 0.3
                        cursor: inherit
                        pointer-events: none;

			.sharing
				background-color: #F49E75
				width: 100%

</style>
