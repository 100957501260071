<!-- Memory vue -->

<template lang="pug">
div.accordion.memory-bloc
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Memories
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()
			div.title.tab-title
				//span.col.col1 Type 
				span.col.col1 Name
				//span.col.col2 Name
				span.col.col2
				span.col.col3 
				span.col.col4 
				div.clear

			div.form-accordion-content.open

				div.list-memory

					div.memory-line(v-for="(memory,i) of items", v-bind:id="memory.value._id",  v-on:click.stop="openMemory(memory.value._id)")
						//span.col.col1 {{memory.value.format}}
						span.col.col1
							span.icon(v-bind:class="memory.value.format.toLowerCase()")
						span.col.col2 {{memory.value.name}}
						span.col.col3 
						//span.col.col3 {{memory.value.created}}
						span.col.col4(v-on:click.stop="deleteMemory(memory, i)")
							span.bt-trash 
						div.clear
				
				div.big-bt.memory-bt(v-on:click="newMemoryMenu", v-on:mouseleave.stop="mouseLeaveList()")
					span.text Add new memory
					span.bt-plus +

					div.itemList(v-bind:class="[{'open':itemListOpen}, sideList]", v-bind:style="{'max-height': listMaxHeight+'px'}")
						ul(v-for="(parent,i) of memoryTypes")
							li.title
								span {{parent}}
							li.item(v-for="type of typeObjects[i]", v-on:click="addMemory(type)")
								span {{type.value.name}}
								
</template>

<script>

// import { stores } from '../../../stores/MainStore.js';
import actionManager from '../../../actions';
import * as helper from '../../../helper';

export default {

	props:[ 'appConfig', 'project', 'target', 'inspectorConf' ],

	data() {
		return {
			accordion: true,
			// items: [],
			typeObjects: [],
			memoryTypes: [],
			itemListOpen: false,
			icons: [],
			iconsON: [],
			sideList: null,
			listMaxHeight: 0
		}
	},

	computed: {
		items: function() {
			let items = [];
			if( this.target.value.memory && this.target.value.memory.length > 0 ){
				for( let item of this.target.value.memory ){
					let memory = helper.block.getMemoryById( this.project, item.id );
					items.push( memory );
				}
			}
			return items;
		}
	},

	created: function(){
		
		for( let inspector of this.inspectorConf ){
			let indexParent = this.memoryTypes.indexOf( inspector.value.parent );
			if( indexParent == -1 ){
				this.memoryTypes.push( inspector.value.parent );
				this.typeObjects.push( [] );
				indexParent = this.memoryTypes.length-1;
			}

			this.typeObjects[indexParent].push( inspector );

				
			//this.typeObjects.push(inspector);
			this.icons[inspector.value.type] = inspector.value.icon;
			this.iconsON[inspector.value.type] = inspector.value.iconON;
		}
		


	},


	methods:{

		newMemoryMenu: function( e ){

			if( !this.itemListOpen ){ // La liste est fermée -> on calcule sa taille max et sa position et on l'ouvre
				let panelHeight = e.view.innerHeight - 69;
				let middle = panelHeight * 0.5;

				if( e.screenY > middle ){
					this.sideList = 'bottom';
				}
				else{
					this.sideList = 'top';
				}

				this.listMaxHeight = middle*0.8;

				this.itemListOpen = true;
			
			}
			else{
				this.listMaxHeight = 0;
				this.itemListOpen = true;
			}

		},

		addMemory: function( choice ){
			actionManager.trigger('project:addMemory', {choice, targetId: this.target.value._id})
		},

		deleteMemory: function( memory, index ) {
			let blocIsMemory = true
			let memIsUsedIn = helper.block.memIsUsedIn(this.project, memory.value._id)
			if(memIsUsedIn.length > 0) {
				actionManager.trigger('main:modal:openModal', {project: this.project, blocIsMemory: blocIsMemory, config: this.appConfig, title:'Are you sure you want to delete this memory ?', isUsedBy: memIsUsedIn, text:'', nextAction:'block:deleteBlock', nextActionParam: { id :  memory.value._id }, icon: 'delete' })
			} else {
				actionManager.trigger('project:deleteMemory', { memId: memory.value._id, targetId: this.target.value._id })
			}
		},

		openMemory: function( id ){
			actionManager.trigger('inspector:loadObj', { targetId: id })
		},

		closeMemory: function(){
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit('close-obj-inspector') // <---- ORIGINAL closeObjInspector LISTENER NOT FOUND
		},

		mouseLeaveList: function(){
			this.itemListOpen = false;
			this.listMaxHeight = 0;
		},


	}

}

</script>

<style lang="stylus">

itemListPosition = var(--itemListPosition)

.memory-bloc
	font-family: 'DinBold', 'ComfortaaLight'
	font-size: 12px
	color: #58585B

	.form-accordion .title.tab-title
		padding: 0px 5px

	span.col
		float:left
		display: inline-block
		width: 33%
		text-align: left
		line-height: 40px
		vertical-align: middle

		&.col1
			width: 19%
			text-transform: capitalize
			height: 40px
			line-height: 40px
			margin-left: 10px

		&.col2
			width: 50%
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis

		&.col3
			width: 20%

		&.col4
			width: 10%
			.bt-trash
				position: absolute !important
				top: 10px !important
				right: 8px !important
				width: 20px !important
				height: 20px !important
				border-radius: 20px !important
				background: color

	.tab-title
		.col
			line-height: 40px
			color: var(--inspectorText)

	.memory-line
		border-radius: 2px
		margin: 1px
		margin-bottom: 3px
		position: relative
		cursor: pointer

	.memory-bt
		cursor: pointer

	.icon
		width: auto
		height: 21px
		display: inline-block
		vertical-align: middle
		transform: none

.itemList
	z-index: 90
	position: absolute
	display:block
	left: 10%
	width: 80%
	background-color: var(--bgColor)
	box-shadow 0px 0px 10px rgba(0,0,0,0.1)
	overflow: hidden
	max-height: 0px
	opacity: 0
	transition: all 0.3s cubic-bezier(0.770, 0.000, 0.175, 1.000)
	transform: scale(1)
	overflow: auto
	border-radius: 16px

	&.top
		top: 30px
		//transform-origin: 50% 100%

	&.bottom
		bottom: 35px
		//transform-origin: 50% 0%

	&.open
		opacity: 1
		transform: scale(1)
		transition: all 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000)

	ul
		padding: 0px!important
		margin: 10px!important
		list-style-type: none
		overflow: hidden
		background: var(--linkMenuColor)
		border-radius: 8px

		&:last-child
			padding-bottom: 10px

		li
			display: block
			padding: 0px 10px
			height: 32px
			line-height: 32px
			border-radius: 4px
			color: #707070
			font-size: 14px
			margin: 5px
			background: var(--bgColor)
			transition: all 0.2s ease-in-out
			&:hover
				background: var(--inspectorTargetOverColor)!important

			&.title
				background-color: var(--level2Color) !important
				border-radius: 8px 8px 0 0 !important
				height: 32px !important
				line-height: 32px !important
				font-family: 'DinBold' !important
				color: white
				margin: 0!important

			&.item
				cursor: pointer
				&:hover
					background-color: #E6E7E8


</style>



