
<template lang="pug">
div#content.refs-ctn
	h1 {{name}}

	div.inspector(v-if="inspector")

		p(v-if="description != ''") {{description}}

		div(v-for="topic of inspector.children")
			h2 {{topic.title}}
			div(v-for="(groupOrField,i) of topic.children") 

				table#settings(v-if="groupOrField.type !=='group'")
					tr#line1(v-if="i==0")
						th#col1 Name
						th#col2 Type
						th#col3 Description
						th#colIn In
						th#colOut Out
					tr(v-bind:class="{'noHeader': i!=0, 'pair' : i%2==0 , 'impair' : i%2==1 }")
						td#col1 {{groupOrField.label}}
						td#col2 {{groupOrField.coreFormat}}
						td#col3
							ul
								li {{groupOrField.description}}
								li {{stringifyOptions(groupOrField.options)}}
						td#colIn &nbsp;
						td#colOut &nbsp;

				div(v-else) 
					h3 {{groupOrField.title}}
					table#settings(v-for="groupfield in groupOrField.children")
						tr#line1
							th#col1 Name
							th#col2 Type
							th#col3 Description
							th#colIn In
							th#colOut Out
						tr(v-for="field in groupfield.children")
							td#col1 {{field.label}}
							td#col2 {{field.coreFormat}}
							td#col3
								ul
									li {{field.description}}
									li {{stringifyOptions(field.options)}}
							td#colIn(v-if="field.connection && field.connection.in && field.connection.in.pluggable") &#10003;
							td#colIn(v-else) &nbsp;
							td#colOut(v-if="field.connection && field.connection.out && field.connection.out.pluggable") &#10003;
							td#colOut(v-else) &nbsp;



	div#back(v-if="!inPanel")
		a(v-on:click.stop="goBack()") &larr; Back to list
</template>

<script>

	import * as helper from '../../helper';
	import actionManager from '../../actions';

	export default {


		props:['type', 'subType', 'appState', 'displayTab', 'inPanel' ],

		data() {
			return {
				name: null,
				description : null
			};
		},

		computed:{
			'inspector': function(){
				let config = null;
				let inspector = null;
				if( this.type === "module" )
					config =  helper.config.getConfigByType(this.appState.config, this.subType );
				else if( this.type === "object" )
					config =  helper.config.getConfigByType(this.appState.config, this.type, this.subType );

				if( config && config.value && config.value.inspector ){
					inspector = config.value.inspector;
					this.name = config.value.name;
					this.description = config.value.resume || config.value.description;
				}
				else{
					actionManager.trigger('doc:openDoc', {type: this.type === 'module' ? 'process' : 'object' } );
					//this.displayTab('ref');// if no config found, back to ref.
				}
				return inspector;
			}
		},

		created: function(){
			actionManager.trigger('doc:openDoc', {type: this.type === 'module' ? 'process' : 'object' } );
		},

		methods:{
			stringifyOptions: function( options ){
				let val = "";
				if( !Array.isArray( options ) ) return val;
				options.forEach( (o) => { val += o.label+", "; })
				return val;
			},

			goBack: function(){
				if( this.type === "module" )
					this.displayTab('ref');
				else
					this.displayTab('obj');
			}
		}

	}

</script>

<style>

</style>