/*
	Library Store instance -
	all function to get info of Library
*/

import uuid from 'uuid/v4';
import deepCopy from 'deepcopy';

import { EventEmitter } from 'events';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

class BlockStore extends EventEmitter {
	constructor(){
		super();
	}

	setMain( main ){
		this.main = main;
	}

	/**
	 * createNewDoc 
	 * ! the promise is useless.
	 * @param {*} doc 
	 * @param {*} overrideIfId 
	 * @returns data - formatted structured for the blocs.
	 */
	createNewDoc( doc, overrideIfId = true ){
		return new Promise( function(resolve, reject){

			if (!doc._id || overrideIfId === true) {
				doc._id = uuid();
			}

			var data = {
				value: doc,
				custom: {
					children: [],
					objects: []
				}
			};
			resolve( data );
		}.bind(this));
	}

	saveDocument( doc , _url = null ){
		/** 
		 * DATABASE WORKSHOP NS-286
		 * ! this call should have an authentification token and pass by the backend server
		 * ! nothing is secure and every user could do this call
		 * ! We should use the token/cookie used with couchDB login.
		*/
		return new Promise( function(resolve, reject){
			let url = _url !== null ? _url : this.main.config.baseURL;
			Vue.http.post( url , doc.value ).then(
				function (response){
					doc.value._rev = response.body.rev
					resolve( doc );
				},
				function (error) {
					//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
					reject(error);
				}
			);
		}.bind(this));
	}


	blocNameExists( name, type, parent ){
		if( parent.custom && parent.custom.children && parent.custom.children.length > 0 ){
			for (var i = 0; i < parent.custom.children.length; i++) {
				var otherBlock = parent.custom.children[i];
				if( otherBlock.value.name == name && otherBlock.value.type == type  && !otherBlock.value._deleted  )
					return true;
			}
		}
		return false;
	}

	/**
	 * Define the document base for all blocks.
	 * @param {Object} bloc - The block configuration.
	 * @param {Object} parent - The block parent.
	 * @param {Object} targets - The block pattern.
	 */
	generateNewBlockDoc( bloc, parent, pattern=null ) {

		// Check how much blocks of the same type is contains on the parent
		let occurenceBlock = "";
		let occurenceSuffix = "";
		var name = "Untitled";//bloc.value.name;

		if( parent ){
			while( this.blocNameExists(name, bloc.value.type, parent) ){
				occurenceSuffix = " ";
				if( occurenceBlock == "" )occurenceBlock = 2;
				else occurenceBlock++;

				name = "Untitled"+occurenceSuffix+occurenceBlock;
			}
		}

		let doc = {
			"type": bloc.value.type,
			"name": name,
			"created": (new Date()).getTime(),
			"fields": []
		};

		if(bloc.value.deprecated){
			Object.assign(doc, {"deprecated": bloc.value.deprecated})
		}

		if(bloc.value.buildOnly){
			Object.assign(doc, {"buildOnly": bloc.value.buildOnly})
		}

		if( parent && parent.value.type ){
			doc["level"] = parent.value.type;
			doc["ref_"+parent.value.type] = parent.value._id;
			if (bloc.value.type.indexOf("template-") != -1) doc.ref_template = pattern.template.id;
		}

		// @todo: targets seems never used
		// check if the message below sometimes appear, if not this bloc could be removed
		
		if (pattern && pattern.targets !== null) doc.targets = pattern.targets;

		if( bloc.value.type == "trigger" ){
			doc.events = [];
			doc.trigger_states = [];
		}

		if( bloc.value.type == "process" ) doc.autoStart = bloc.value.autoStart;
		
		this.createBlockStates(doc, bloc);

		if( ['memory', 'object'].indexOf( bloc.value.type ) == -1 ) doc.links = [];

		if( bloc.value.type != "module" && bloc.value.type != "object" && bloc.value.type != "memory" ){
			doc.memory = [];
		}

		return doc;
	}

	createBlockStates(doc, blocPattern) {
		if( blocPattern.value.states ){
			let statesOfPatterns = Object.keys( blocPattern.value.states );
			if( statesOfPatterns && statesOfPatterns.length > 0 ){
				doc.states = {};
				statesOfPatterns.forEach( (state) => {
					doc.states[ state ] = deepCopy( blocPattern.value.states[ state ] );
				});
			}
		}
	}


	// add new block to parent
	addBlockToParent( blockData , parent ){
		return new Promise( function(resolve, reject){

			let blockDoc = this.generateNewBlockDoc( blockData , parent );

			// display loading screen
			this.main.project.dispatchWaitInfo( true );

			let self = this;

			// in order :
			// - create new doc
			// - add ui object associated
			// ?? add here other associated objects/block
			// - add this bloc to custom.children list

			self.createNewDoc( blockDoc )
				.then( function( newBloc ){
						return self.main.objects.createObjectAssociated( blockData, newBloc )
					})
				.then( function( newBloc ){
						return self.newblockAdded( parent, newBloc )
					})
				.then( function( newBloc){
					self.main.project.dispatchWaitInfo( false );
					resolve( newBloc );
				});

		}.bind(this));
	}

	addBlockAssociated( blockData, newBloc ){

		// check if this block also need to create automaticly children (like ui-objects) -- add them to it's children
		this.main.objects.createObjectAssociated( blockData, newBloc ).then( this.newblockAdded );

	}

	newblockAdded( parent, newBloc ){
		return new Promise( function(resolve, reject){

			if( !parent.custom )
				parent.custom = new Object();

			if( !parent.custom.children )
				parent.custom.children = [];

			parent.custom.children.push( newBloc );

			resolve( newBloc );
		}.bind(this));
	}

	// @deprecated
	getEntityByIDInWholeProject( idEntity ){
		let mem = this.main.memory.getMemoryByIDInProject( this.main.config.openProject, idEntity );
		if( mem ) return mem;

		let asset = this.main.lib.getLoadedMediaByID( idEntity );
		if( asset ) return asset;

		let bloc = this.getEntityByIDInProject( this.main.config.openProject, idEntity );
		if( bloc ) return bloc;

		return null;
	}

	// @deprecated
	getEntityByIDInProject( node, idEntity, identifier = "_id" ){

		if( node.value[identifier] == idEntity )
			return node;

		// go through session children
		if( node.sessions && node.sessions.length > 0 ){
			let result = null;
			for( let subnode of node.sessions ){
				let child = this.getEntityByIDInProject( subnode, idEntity, 'slug' );
				if( child )
					result = child;
			}
			if( result )
				return result;
		}
		// go through block children
		if( node.custom.children && node.custom.children.length > 0 ){
			let result = null;
			for( let subnode of node.custom.children ){
				let child = this.getEntityByIDInProject( subnode, idEntity, identifier );
				if( child )
					result = child;
			}
			if( result )
				return result;
		}
		// go through UIobjects children
		if( node.custom.objects && node.custom.objects.length > 0 ){
			let result = null;
			for( let subnode of node.custom.objects ){
				let child = this.getEntityByIDInProject( subnode, idEntity, identifier );
				if( child )
					result = child;
			}
			if( result )
				return result;
		}

		return null;
	}

	deleteDocument( doc , url = null ){
		return new Promise( function(resolve, reject){

			let docId
			if (typeof doc === "object" && doc.value !== undefined) {
				docId = doc.value._id;
			} else if (typeof doc === "string") {
				docId = doc;
			}

			let baseURL = url === null ? this.main.config.baseURL : url;
			var urlGet = baseURL+docId;
			var self = this;

			/** 
			 * DATABASE WORKSHOP NS-286
			 * ! this call should have an authentification token and pass by the backend server
			 * ! nothing is secure and every user could do this call
			 * ! We should use the token/cookie used with couchDB login.
			*/
			Vue.http({url: urlGet, method: 'GET'}).then(function (response) {

				if( response && response.body ){
					let data = {
						docs: [
							{
								_id: response.body._id,
								_rev: response.body._rev,
								_deleted: true
							}
						]
					}

					let url = baseURL+'_bulk_docs';
					/** 
					 * DATABASE WORKSHOP NS-286
					 * ! this call should have an authentification token and pass by the backend server
					 * ! nothing is secure and every user could do this call
					 * ! We should use the token/cookie used with couchDB login.
					 * ! it should also be a post request because it's clearly modify the database documents.
					*/
					Vue.http.post( url , data ).then(function (response) {
						if( response )
							resolve();
					});
				}
				else{
					reject();
				}


			});


		}.bind(this));
	}




	getParentsID( node ){
		if(	node.value.ref_block )
			return node.value.ref_block;
		if( node.value.ref_process )
			return node.value.ref_process;
		if( node.value.ref_screen )
			return node.value.ref_screen;
		if( node.value.ref_experience )
			return node.value.ref_experience;
		if( node.value.ref_project )
			return node.value.ref_project;
		if( node.value.level && node.value['ref_'+node.value.level] )
			return node.value['ref_'+node.value.level];
		return null;
	}



	triggerConnectionChange( blocInfo ){

		//this.checkNewConnectionOfBloc( blocInfo )
		if( blocInfo ){

			if( blocInfo.blocID && blocInfo.field ){
				let bloc = this.getEntityByIDInProject( this.main.config.openProject, blocInfo.blocID );
				this.deleteLinkToField( this.main.config.openProject , bloc, blocInfo.field.name , blocInfo.side );
			}

			this.emit('connectionChange', blocInfo );
		}


	}



	deleteNodeChildren( node ){
		// delete block children
		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				subnode.value._deleted = true;
				this.deleteNodeChildren( subnode );
			}
		}
		// delete UIobjects children
		if( node.custom.objects && node.custom.objects.length > 0 ){
			for( let subnode of node.custom.objects ){
				subnode.value._deleted = true;
				this.deleteNodeChildren( subnode );
			}
		}
	}

	deleteLinkToField( node, bloc, fieldname , side ){

		if( node.value.links && node.value.links.length > 0 ){
			// Suppression des links qui font ref a ce bloc dans ce noeud
			let linksToDelete = [];
			for (var i = 0; i < node.value.links.length; i++) {

				// is current node id of link from or to is equal to bloc.id
				if( ( node.value.links[i].from && node.value.links[i].from.blocID == bloc.value._id ) || ( node.value.links[i].to && node.value.links[i].to.blocID == bloc.value._id ) ){

					let currentBlocSide = ( bloc.value._id  == node.value.links[i].from.blocID ) ? node.value.links[i].from : node.value.links[i].to;

					if( fieldname == currentBlocSide.name ){

						if( side == null ){
							// Si on ne precise pas le coté du lien , on le supprime quoi qu'il arrive
							linksToDelete.push( i );
						}
						else if( side != null && currentBlocSide.side == side ){
							// Sinon on vérifie que le lien ciblé a bien le champ concerné du bon coté (input ou output)
							// side = input or output
							linksToDelete.push( i );
						}
					}

				}

			}

			let j = linksToDelete.length-1;
			while( linksToDelete.length > 0 ){
				node.value.links.splice( linksToDelete[j], 1 );
				linksToDelete.splice( j , 1 );
				j = linksToDelete.length-1;
			}
		}


		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.deleteLinkToField( subnode, bloc, fieldname , side );
			}
		}

	}


	deleteLinkToMemoryField( node, memoryId, colIndex ){

		let fieldname = memoryId+"_"+colIndex;

		if( node.value.links && node.value.links.length > 0 ){
			// Suppression des links qui font ref a cette col dans ce noeud
			let linksToDelete = [];
			for (var i = 0; i < node.value.links.length; i++) {
				if( fieldname == node.value.links[i].from.name || fieldname == node.value.links[i].to.name ){
					linksToDelete.push( i );
				}

			}

			let j = linksToDelete.length-1;
			while( linksToDelete.length > 0 ){
				node.value.links.splice( linksToDelete[j], 1 );
				linksToDelete.splice( j , 1 );
				j = linksToDelete.length-1;
			}
		}


		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.deleteLinkToMemoryField( subnode, memoryId, colIndex );
			}
		}

	}


	deleteLinkToNode( node, bloc ){

		if( node.value.links && node.value.links.length > 0 ){
			// Suppression des links qui font ref a ce bloc dans ce noeud
			let linksToDelete = [];
			for (let i = 0; i < node.value.links.length; i++) {

				if( node.value.links[i].from && node.value.links[i].from.blocID == bloc.value._id )
					linksToDelete.push( i );

				if( node.value.links[i].to && node.value.links[i].to.blocID == bloc.value._id )
					linksToDelete.push( i );

			}

			let j = linksToDelete.length-1;
			while( linksToDelete.length > 0 ){
				node.value.links.splice( linksToDelete[j], 1 );
				linksToDelete.splice( j , 1 );
				j = linksToDelete.length-1;
			}
		}


		if( bloc.value.type == "memory" ){
			// Si le bloc supprimé est une memory, on regarde si une ref a cette memory est présente dans la liste de memory du noeud courant
			if( node.value.memory && node.value.memory.length > 0 ){
				let memoryRefToDelete = [];

				for (let i = 0; i < node.value.memory.length; i++) {
					if( node.value.memory[i].id == bloc.value._id )
						memoryRefToDelete.push( i );

				}

				let j = memoryRefToDelete.length-1;
				while( memoryRefToDelete.length > 0 ){
					node.value.memory.splice( memoryRefToDelete[j], 1 );
					memoryRefToDelete.splice( j , 1 );
					j = memoryRefToDelete.length-1;
				}

			}
		}


		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.deleteLinkToNode( subnode, bloc );
			}
		}


		//this.links.splice(this.links.indexOf(link), 1);
	}


	deleteBloc( bloc ){
		bloc.value._deleted = true;
		this.deleteNodeChildren( bloc );
		this.deleteLinkToNode( this.main.config.openProject, bloc );

		this.main.project.saveProject( this.main.config.openProject );
	}


	deleteLinkToBloc( bloc ){
		this.deleteLinkToNode( this.main.config.openProject, bloc );
		this.main.project.saveProject( this.main.config.openProject );
	}


	getValueOfField( bloc , fieldFormat ){
		let value = null;

		// init value field with settings of the block type
		let fieldName = fieldFormat.repeatedName ? fieldFormat.repeatedName : fieldFormat.name;
		let field = this.getField( bloc, fieldName );

		if( field == null ){
			// Si des valeurs pour ce champ n'ont pas encore été setté en base, on prend les valeurs par default

			let pluggable = null;
			if( fieldFormat.type == "field" ){
				pluggable = {in:fieldFormat.connection.in.pluggable, out:fieldFormat.connection.out.pluggable};
			}
			let defaultValue = {name:fieldName, value:null, in:false, out:false, pluggable: pluggable };


			if( fieldFormat.default !== null && typeof fieldFormat.default === 'object')
				defaultValue.value = Object.assign( {}, fieldFormat.default ); // use a copy of the object, to not use the default object itself
			else
				defaultValue.value = fieldFormat.default;

			if( fieldFormat.connection && fieldFormat.connection.in.pluggable && fieldFormat.connection.in.default )
				defaultValue.in = true;

			if( fieldFormat.connection && fieldFormat.connection.out.pluggable && fieldFormat.connection.out.default )
				defaultValue.out = true;

			bloc.value.fields.push(defaultValue);
			let field = this.getField( bloc, fieldName );

			value = field;

		}
		else{
			// On récupérer la valeur contenue en base

			// On vérifie que le champ est toujours "pluggable" (modification possible du coté BDD)
			if( fieldFormat.type == "field" ){
				field.pluggable = {
					in:fieldFormat.connection.in.pluggable,
					out:fieldFormat.connection.out.pluggable
				};
				// Si ce n'est pas le cas, on remet a false
				if( !fieldFormat.connection.in.pluggable )
					field.in = false;

				if( !fieldFormat.connection.in.pluggable )
					field.in = false;
			}

			// On set la valeur en base
			value = field;
		}

		return value;
	}


	getField( bloc, fieldname ){
		if( bloc.value.fields && bloc.value.fields.length > 0 ){
			for( let field of bloc.value.fields ){
				if( field.name == fieldname )
					return field;
			}
		}
		return null;
	}

	removeField( bloc, fieldname ){

		let cpt = 0;
		if( bloc.value.fields && bloc.value.fields.length > 0 ){
			for( let field of bloc.value.fields ){
				if( field.name == fieldname ){
					bloc.value.fields.splice(cpt, 1);
					return;
				}
				cpt++;
			}
		}
		return;
	}


	getRepeatedValues( bloc, fieldname ){
		let currentFields = [];
		let currentFieldsIds = [];
		bloc.value.fields.forEach( (field) =>{
			if( field && field.name ){
				let isRepeatedValue = field.name.search( fieldname+"_" );
				if( isRepeatedValue != -1 ){

					let name = field.name.replace(fieldname+"_", '');
					let under = name.indexOf("_");
					let uid = name.substring(0, under);

					if( currentFieldsIds.indexOf( uid ) == -1 )
						currentFieldsIds.push( uid );

					let index = currentFieldsIds.indexOf( uid );

					let repeatedField = Object.assign({}, field);
					repeatedField.index = index+1;
					currentFields.push( repeatedField );
				}
			}
		});
		return currentFields;
	}


	deleteLinkFromBloc( bloc, link ){
		let linkIndex = -1;
		let cpt = 0;
		for( let linkOfBloc of bloc.value.links ){
			if( link.from.blocID == linkOfBloc.from.blocID && link.from.name == linkOfBloc.from.name && link.to.blocID == linkOfBloc.to.blocID  && link.to.name == linkOfBloc.to.name ){
				linkIndex = cpt;
			}
			cpt++;
		}

		if( linkIndex != -1 )
			bloc.value.links.splice(linkIndex, 1);

	}

	cleanLinkPart( linkPart ){
		delete linkPart.value;
		delete linkPart.default;
	}

	addLinkToBloc( from, blocFrom, to, blocTo ){
		return new Promise( function(resolve, reject){

			if( !this.checkLink( from, blocFrom, to, blocTo ) ){
				reject();
				return;
			}

			this.cleanLinkPart( from );
			this.cleanLinkPart( to );

			if( !blocFrom.value.links )
				blocFrom.value.links = [];

			let newLink = { from: from, to: to, activated: null } ;
			blocFrom.value.links.push( newLink );

			resolve( blocFrom.value.links[ blocFrom.value.links.length-1 ] ); // return new link created

		}.bind(this));
	}


	checkLink( from, bloc1, to, bloc2 ){

		if( from.side == to.side )
			return false; // Deux liens ne peuvent pas être du meme coté (output to output ou input to input)

		if( this.isLinkExist( bloc1, from.name , bloc2 , to.name ) || this.isLinkExist( bloc2, to.name, bloc1, from.name ) )
			return false;

		return true;
	}

	isLinkExist( bloc1 , fieldFrom, bloc2 , fieldTo ){
		if( bloc1.value.links && bloc1.value.links.length > 0 ){
			for (var i = 0; i < bloc1.value.links.length; i++) {
				if( bloc1.value.links[i].from.name == fieldFrom && bloc1.value.links[i].to.name == fieldTo && bloc1.value.links[i].to.blocID  ==  bloc2.value._id )
					return true;
			}
		}
		return false;
	}

	capitalizeFirstLetter(string) {
	    return string.charAt(0).toUpperCase() + string.slice(1);
	}

	isConversionPossible( inputType , outputType ){

		let conversion = false;

		inputType = this.capitalizeFirstLetter( inputType );
		outputType = this.capitalizeFirstLetter( outputType );


		// TEMP FIX
		inputType = (inputType == "Number") ? 'Float' : inputType;
		outputType = (outputType == "Number") ? 'Float' : outputType;

		inputType = (inputType == "Object") ? 'DataObject' : inputType;
		outputType = (outputType == "Object") ? 'DataObject' : outputType;

		inputType = (inputType == "Bool") ? 'Boolean' : inputType;
		outputType = (outputType == "Bool") ? 'Boolean' : outputType;

		let defaultConv = outputType +" to "+ inputType;

		if( inputType == "Mixed" || outputType == "Mixed" )
			conversion = defaultConv;

		if( inputType == outputType )
			conversion = "";

		let conversionProps = null;
		if( this.main.config.datatypes[outputType] )
			conversionProps = this.main.config.datatypes[outputType]['to'+inputType];

		if( conversionProps && conversionProps.length >= 1 )
			conversion = conversionProps;
		else if( conversionProps && conversionProps.length == 0 )
			conversion = defaultConv;

		return conversion;
	}



	/**
	** Check condition for a field
	** retrun false if conditions for this field are not valid, return true otherwise
	** bloc : ref to the current bloc
	** field : field to check
	** valuesTab (optional) : current values of the bloc's fields , if null : this function use default value define in inspector of bloc
	**/
	checkFieldCondition( bloc, field, valuesTab = null , inspector = null ){

		if( !field.conditions || field.conditions.length == 0 )
			return true;

		// let display = true;
		for (var i = 0; i < field.conditions.length; i++) {

			let operator = field.conditions[i].operator;
			let compare = field.conditions[i].value;

			let fieldName = field.conditions[i].field;
			
			// is condition has to be check in a field of the same reapeated form
			if( field.conditions[i].inRepeatedForm === true ){
				// get suffit for this current repeated form
				let currentFormName = field.formName;
				let currentName = field.name;
				currentName = currentName.replace(currentFormName, '');

				// and adds the field name to check
				fieldName = currentName+field.conditions[i].field;
			}
			let inspectorOfBloc = inspector ? inspector : this.main.authoring.findSettingByType( bloc.value.type, bloc.value.format ).value.inspector;

			let currentVal = null;
			if( valuesTab ){
				// the valuestab is sent, just get the current form it
				currentVal = valuesTab[fieldName].value;
			}
			else {
				// otherwise, get inspector of the bloc to check if the data is already saved or if we need to use the default value

				//let inspectorField = this.main.authoring.findFieldInInspector( inspectorOfBloc.value.inspector , fieldName );
				let refFieldName = fieldName;
				let subField = null;
				if( field.conditions[i].inRepeatedForm === true ){
					refFieldName = fieldName.substring( 0, fieldName.indexOf('_') );
					subField = field.conditions[i].field;
				}

				let inspectorField = this.main.authoring.findFieldInInspector( inspectorOfBloc , refFieldName, subField );
				let conditionField = null;

				if( field.conditions[i].inRepeatedForm === true ){
					inspectorField.repeatedName = fieldName;
				}

				if (inspectorField)
					conditionField = this.getValueOfField( bloc, inspectorField );

				if( conditionField )
					currentVal = conditionField.value;

					/*if( !currentVal )
					currentVal = bloc.custom.fields && bloc.custom.fields[conditionField.name] ? bloc.custom.fields[conditionField.name].value : undefined;*/

				// @todo: prefers search for the default value when currentVal still equal to null
				// wheter than set to the known default value
				if (!currentVal) {
					// set default mode for UI object to 'create'
					if (fieldName === 'moduleMode' && bloc.custom.tag === 'ui') currentVal = 'create';
				}
			}

			if( field.conditions[i].property || field.conditions[i].fieldValue ){
				// If a sub property is define, this condition concern an object type containing multiple sub-property
				// we need to load the field value and check for the property inside
				if (currentVal) {
					let entity = this.getEntityByIDInWholeProject(currentVal);
					if (entity) {
						if (entity.value)
							entity = entity.value;

						if (field.conditions[i].property && entity[field.conditions[i].property])
							currentVal = entity[field.conditions[i].property];

						if (field.conditions[i].fieldValue) {
							let entityField = this.getField({ value: entity }, field.conditions[i].fieldValue);
							if (entityField && entityField.value !== undefined)
								currentVal = entityField.value;
						}
					}
					// @todo: fix me, this should not happen (missing memory target generally)
					else console.warn(`Failed to find entity [${currentVal}] for the ${bloc.value.type} '${bloc.value.name}' for field "${field.label}"`);
				}
			}

			// Concerns fields that must be displayed according to a dynamic field unknown by the app.
			// For instance, the value field in the Memory Access Module. Attempt to be as generic as possible but may need refac
			// for futur needed addition.
			if(field.conditions[i].dynamicElement) {

				let dynamicElement = field.conditions[i].dynamicElement
				let paramtoGet = field.conditions[i].param;
				let refFieldName = field.conditions[i].field;

				let inspectorField = this.main.authoring.findFieldInInspector( inspectorOfBloc , refFieldName, null );
				let refField = this.getValueOfField( bloc, inspectorField );
				let refFieldParam = refField[paramtoGet];

				let blockRef = this.getEntityByIDInWholeProject(refFieldParam);
				if (blockRef) {
					currentVal = blockRef.value[dynamicElement].toLowerCase();
					if( currentVal === 'array') {
						let header = blockRef.value.fields.find((field) => { return field.name === 'value' }).header;
						let colField = this.main.authoring.findFieldInInspector( inspectorOfBloc , 'column', null );
						let colValue = this.getValueOfField(bloc, colField).value || 0;
						if (header) {
							if (colValue<0 || colValue >= header.length) {
								console.log(`Invalid column number for module '${bloc.value.name}', use the default column 0 instead.`);
								colValue = 0;
							}
							currentVal = header[colValue].widget.toLowerCase();
						}
					}
				}
				if(field.conditions[i].fieldParam){
					compare = field[field.conditions[i].fieldParam].toLowerCase()
				}
			}
			
			if( currentVal !== null ){
				switch( operator ){
					case '==':
						if( currentVal != compare )
							return false;
						break;

					case '!=':
						if( currentVal == compare )
							return false;
						break;

					case '>':
						if( currentVal <= compare )
							return false;
						break;

					case '<':
						if( currentVal >= compare )
							return false;
						break;

					case '>=':
						if( currentVal < compare )
							return false;
						break;

					case '<=':
						if( currentVal > compare )
							return false;
						break;
				}
			} else {
				return false
			}

		}

		return true;
	}

}

export let block = new BlockStore();
