var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "refs-ctn", attrs: { id: "content" } }, [
    _c("h1", [_vm._v(_vm._s(_vm.name))]),
    _vm.inspector
      ? _c(
          "div",
          { staticClass: "inspector" },
          [
            _vm.description != ""
              ? _c("p", [_vm._v(_vm._s(_vm.description))])
              : _vm._e(),
            _vm._l(_vm.inspector.children, function(topic) {
              return _c(
                "div",
                [
                  _c("h2", [_vm._v(_vm._s(topic.title))]),
                  _vm._l(topic.children, function(groupOrField, i) {
                    return _c("div", [
                      groupOrField.type !== "group"
                        ? _c("table", { attrs: { id: "settings" } }, [
                            i == 0
                              ? _c("tr", { attrs: { id: "line1" } }, [
                                  _c("th", { attrs: { id: "col1" } }, [
                                    _vm._v("Name")
                                  ]),
                                  _c("th", { attrs: { id: "col2" } }, [
                                    _vm._v("Type")
                                  ]),
                                  _c("th", { attrs: { id: "col3" } }, [
                                    _vm._v("Description")
                                  ]),
                                  _c("th", { attrs: { id: "colIn" } }, [
                                    _vm._v("In")
                                  ]),
                                  _c("th", { attrs: { id: "colOut" } }, [
                                    _vm._v("Out")
                                  ])
                                ])
                              : _vm._e(),
                            _c(
                              "tr",
                              {
                                class: {
                                  noHeader: i != 0,
                                  pair: i % 2 == 0,
                                  impair: i % 2 == 1
                                }
                              },
                              [
                                _c("td", { attrs: { id: "col1" } }, [
                                  _vm._v(_vm._s(groupOrField.label))
                                ]),
                                _c("td", { attrs: { id: "col2" } }, [
                                  _vm._v(_vm._s(groupOrField.coreFormat))
                                ]),
                                _c("td", { attrs: { id: "col3" } }, [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(_vm._s(groupOrField.description))
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.stringifyOptions(
                                            groupOrField.options
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ]),
                                _c("td", { attrs: { id: "colIn" } }, [
                                  _vm._v(" ")
                                ]),
                                _c("td", { attrs: { id: "colOut" } }, [
                                  _vm._v(" ")
                                ])
                              ]
                            )
                          ])
                        : _c(
                            "div",
                            [
                              _c("h3", [_vm._v(_vm._s(groupOrField.title))]),
                              _vm._l(groupOrField.children, function(
                                groupfield
                              ) {
                                return _c(
                                  "table",
                                  { attrs: { id: "settings" } },
                                  [
                                    _vm._m(0, true),
                                    _vm._l(groupfield.children, function(
                                      field
                                    ) {
                                      return _c("tr", [
                                        _c("td", { attrs: { id: "col1" } }, [
                                          _vm._v(_vm._s(field.label))
                                        ]),
                                        _c("td", { attrs: { id: "col2" } }, [
                                          _vm._v(_vm._s(field.coreFormat))
                                        ]),
                                        _c("td", { attrs: { id: "col3" } }, [
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(_vm._s(field.description))
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.stringifyOptions(
                                                    field.options
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        ]),
                                        field.connection &&
                                        field.connection.in &&
                                        field.connection.in.pluggable
                                          ? _c(
                                              "td",
                                              { attrs: { id: "colIn" } },
                                              [_vm._v("✓")]
                                            )
                                          : _c(
                                              "td",
                                              { attrs: { id: "colIn" } },
                                              [_vm._v(" ")]
                                            ),
                                        field.connection &&
                                        field.connection.out &&
                                        field.connection.out.pluggable
                                          ? _c(
                                              "td",
                                              { attrs: { id: "colOut" } },
                                              [_vm._v("✓")]
                                            )
                                          : _c(
                                              "td",
                                              { attrs: { id: "colOut" } },
                                              [_vm._v(" ")]
                                            )
                                      ])
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                    ])
                  })
                ],
                2
              )
            })
          ],
          2
        )
      : _vm._e(),
    !_vm.inPanel
      ? _c("div", { attrs: { id: "back" } }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("← Back to list")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { id: "line1" } }, [
      _c("th", { attrs: { id: "col1" } }, [_vm._v("Name")]),
      _c("th", { attrs: { id: "col2" } }, [_vm._v("Type")]),
      _c("th", { attrs: { id: "col3" } }, [_vm._v("Description")]),
      _c("th", { attrs: { id: "colIn" } }, [_vm._v("In")]),
      _c("th", { attrs: { id: "colOut" } }, [_vm._v("Out")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }